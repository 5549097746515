import { getRootStore } from '@/stores/rootStore';
import { observer } from 'mobx-react';
import React from 'react';
import { InventoryExportDialog } from './InventoryExportDialog';
import EditInventoryDialog from './components/inventory_dialogs/EditInventoryDialog';

@observer
export class InventorySearchDialogs extends React.Component<object> {
  private editStore = getRootStore().editInventoryStore;

  private store = getRootStore().inventoryStore;

  render() {
    const {
      all_units: allUnits,
      handleSubmit,
      exportDialogOpen,
      setDialogExportOpen,
      submitExport,
    } = this.store;
    const editStore = this.editStore;
    const { currentlyEditingInventoryBatches } = editStore;

    return (
      <>
        <EditInventoryDialog
          alwaysShowEditEventFields
          store={editStore}
          units={allUnits}
          batches={currentlyEditingInventoryBatches}
          onSubmit={handleSubmit}
          onDeleteSample={this.store.handleDeleteSample}
          onDeleteEvent={this.store.handleDeleteEvent}
        />
        <InventoryExportDialog
          open={exportDialogOpen}
          onClose={() => setDialogExportOpen(false)}
          onSave={submitExport}
        />
      </>
    );
  }
}
