import React, { ReactNode } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  SxProps,
  Link,
} from '@mui/material';
import { ChemistryImage } from '@/shared/components/ChemistryImage/ChemistryImage';
import { OverlayTopLeft } from './layout';
import { CDD } from '@/typedJS';
import { RouterStore } from '@/stores/routerStore';

export const StructureCardView = (props: {
  structure: string;
  structureSize: number;
  header?: ReactNode;
  footer?: ReactNode;
  selected?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  highlightedAtomNumbers?: number[];
  highlightedStructures?: string[];
  skipHighlightAlignment?: boolean;
  showRegisterLink?: boolean;
  dataTestid?: string;
  children?: ReactNode;
  width?: number;
  height?: number;
  headerSx?: SxProps;
  contentSx?: SxProps;
}) => {
  const {
    structure, structureSize, header, footer, selected, onClick,
    showRegisterLink = false,
    highlightedAtomNumbers, highlightedStructures = [], skipHighlightAlignment = false,
    width, height, headerSx, contentSx, dataTestid,
  } = props;

  const cardWidth = width || structureSize + 10;
  const cardHeight = height || '99.7%';

  const headerElement = (header && (
    <>
      <Stack
        direction='row'
        sx={{
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          justifyItems: 'center',
          fontSize: 'small',
          ...headerSx,
        }}
        divider={<span>,&nbsp;</span>}
      >
        {header}
      </Stack>
      <Divider />
    </>
  )) || null;
  const footerElement = footer || null;
  const cardStyle = {
    width: cardWidth,
    maxWidth: cardWidth,
    minWidth: cardWidth,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: cardHeight,
  };
  if (selected) {
    cardStyle.border = '1px solid #000000';
  }
  const cardContentStyle = {
    width: cardWidth,
    padding: '5px',
    '&:last-child': {
      padding: '5px',
    },
  };

  return (
    <Card
      variant='outlined'
      sx={cardStyle}
      onClick={onClick}
    >
      <CardContent sx={cardContentStyle} >
        <Stack sx={{ justifyContent: 'space-between', gap: '2px', ...contentSx }}>
          <Box
            width={structureSize}
            aria-label='structure'
            data-testid={dataTestid || 'structure-card'}
          >
            <Box
              sx={{
                top: 0,
                left: 0,
              }}
            >
              {showRegisterLink
                ? <OverlayTopLeft zIndex={11}><RegisterLink structure={structure} /></OverlayTopLeft>
                : null}
              <ChemistryImage
                src={structure}
                width={0.9 * structureSize}
                height={structureSize}
                onlyRenderWhenVisible={false}
                highlightedStructures={highlightedStructures}
                skipHighlightAlignment={skipHighlightAlignment}
                highlightedAtomNumbers={highlightedAtomNumbers}
              />
            </Box>
          </Box>
          {headerElement}
          {footerElement}
        </Stack>
      </CardContent>
    </Card >
  );
};

export const RegisterLink = (props: {
  structure: string;
}) => {
  const handleClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const url = `/${CDD.ActiveDataContext.toContextParam}/molecules/new`;
    await RouterStore.openInNewTabWithPost(url, { smiles: props.structure });
  };
  return (
    <Link href='#' target='_blank' rel='noreferrer' onClick={handleClick}>
      Register
    </Link>
  );
};
