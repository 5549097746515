import { molecule_properties } from '../Properties/calculator';
import {
  BioisosterFragment,
  BioisosterSettings,
  DLBioisosterResponse,
  DLError,
  ReferenceStructure,
} from './BioisosterResultsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processBioisostereResults(json: any): DLBioisosterResponse | DLError {
  if (json.result?.suggestions) {
    json.result.suggestions.forEach((suggestion: BioisosterFragment) => {
      suggestion.isExpanded = false;
    });
  }
  return json;
}

const containsPatentInformation = (bioisosterFragments: Array<BioisosterFragment>) => {
  for (const bioisosterFragment of bioisosterFragments) {
    for (const bioisoster of bioisosterFragment.bioisosters) {
      for (const identifier of bioisoster.identifiers) {
        if (identifier.patent) {
          return true;
        }
      }
    }
  }
  return false;
};

export const bioisosterToCSV = (props: {
  reference: ReferenceStructure,
  bioisosterFragments: Array<BioisosterFragment>,
  displayProperties: string[],
  bioisosterSettings: BioisosterSettings,
}) => {
  const addPatents = props.bioisosterSettings.showCollectionInformation && containsPatentInformation(props.bioisosterFragments);

  const header = [
    'Fragment',
    'Suggestion',
    'smiles',
    'tanimoto',
    'synthetic accessibility',
    'selected',
  ];
  if (addPatents) {
    header.push('patent ID', 'first patent', 'patent date');
  }

  const exportProperties = molecule_properties.filter((p) =>
    props.displayProperties.includes(p.key),
  );

  const csvData = [[...header, ...exportProperties.map((p) => p.short)]];

  const row = ['Reference', '', props.reference.structure, 1.0.toFixed(3), '', ''];
  if (addPatents) {
    row.push('', '', '');
  }
  exportProperties.forEach((property) => {
    const value = props.reference.properties[property.key];
    row.push(!value ? '' : value.toFixed(property.precision));
  });
  csvData.push(row);

  props.bioisosterFragments.forEach((bioisosterFragment) => {
    bioisosterFragment.bioisosters.forEach((bioisoster) => {
      const row = [
        bioisosterFragment.fragment,
        bioisoster.fragment,
        bioisoster.structure,
        bioisoster.tanimoto.toFixed(3),
        bioisoster.synthetic_feasibility.synthetic_feasibility_score.toFixed(3),
        bioisoster.selected ? 'selected' : '',
      ];
      if (addPatents) {
        const identifier = bioisoster.identifiers
          .filter((i) => i.patent)
          .sort((a, b) => a.patent.first_date < b.patent.first_date ? 1 : -1);
        if (identifier.length > 0) {
          row.push(
            identifier[0].name || '',
            identifier[0].patent.first_patent || '',
            identifier[0].patent.first_date || '',
          );
        } else {
          row.push('', '', '');
        }
      }
      exportProperties.forEach((property) => {
        const value = bioisoster.properties[property.key];
        row.push(!value ? '' : value.toFixed(property.precision));
      });
      csvData.push(row);
    });
  });
  return csvData.map((row) => row.join(',')).join('\n');
};

export const bioisosterToBlob = (props: {
  reference: ReferenceStructure,
  bioisosterFragments: Array<BioisosterFragment>,
  displayProperties: string[],
  bioisosterSettings: BioisosterSettings,
}) => {
  return new Blob([bioisosterToCSV(props)], {
    type: 'text/csv;charset=utf-8;',
  });
};

export const downloadBioisosters = (props: {
  reference: ReferenceStructure,
  bioisosterFragments: Array<BioisosterFragment>,
  displayProperties: string[],
  bioisosterSettings: BioisosterSettings,
}) => {
  const blob = bioisosterToBlob(props);
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', 'bioisosteres.csv');
  link.click();
};
