import * as React from 'react';
import autobind from 'class-autobind';
import {
  AddButton,
  ColumnDef,
  SimpleDataTable,
  SimpleFilter,
} from '@/shared/components';
import { pluralize } from '@/shared/utils/stringUtils';
import { observer } from 'mobx-react';
import { getRootStore } from '../../stores/rootStore';
import { CustomAminoAcidsSchema } from './types';
import { EditCustomAminoAcidDialog } from './components/EditCustomAminoAcidDialog';
import { Box, Grid, Typography } from '@mui/material';

type ColumnId =
  | 'name'
  | 'vaults_count'
  | 'projects_count'
  | keyof CustomAminoAcidsSchema;

// Table with: Name, Email, Vaults/Projects (project count as a link - projects should be organized by Vault and alphabetical),
const columns: Array<ColumnDef & { id: ColumnId }> = [
  {
    label: 'Name',
    id: 'name',
  },
  {
    label: 'Codes',
    id: 'codes',
  },
  {
    label: 'Description',
    id: 'description',
  },
  {
    label: 'Molecular Weight',
    id: 'molecular_weight',
  },
  {
    label: 'Unmodified Residue',
    id: 'unmodified_amino_acid',
  },
  {
    label: 'Family',
    id: 'family',
  },
];

@observer
export class CustomAminoAcidsPage extends React.Component {
  constructor(props: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    super(props);
    autobind(this);
  }

  handleClickEdit(evt: React.MouseEvent) {
    const {
      accountsStore: {
        customAminoAcidsStore: { customAminoAcids, editCustomAminoAcidStore },
      },
    } = getRootStore();
    const clickedId = evt.currentTarget.getAttribute('data-id');
    const customAminoAcid = customAminoAcids.find((customAminoAcid) => clickedId === '' + customAminoAcid.id);
    editCustomAminoAcidStore.handleEditCustomAminoAcid(customAminoAcid);
  }

  handleClickNew() {
    const {
      accountsStore: {
        customAminoAcidsStore: { editCustomAminoAcidStore },
      },
    } = getRootStore();
    editCustomAminoAcidStore.handleNewCustomAminoAcid();
  }

  handleClickRow(customAminoAcid: CustomAminoAcidsSchema) {
    if (customAminoAcid) {
      const {
        accountsStore: {
          customAminoAcidsStore: { editCustomAminoAcidStore },
        },
      } = getRootStore();

      editCustomAminoAcidStore.handleEditCustomAminoAcid(customAminoAcid);
    }
  }

  renderTopRow() {
    const {
      accountsStore: {
        currentAccount,
        customAminoAcidsStore: {
          customAminoAcids, filterText, setFilterText,
        },
      },
    } = getRootStore();
    return (
      <div className='top-row'>
        <Grid container alignItems='center'>
          <Grid item xs={5}>
            <Box display='flex' justifyContent='left' alignItems='center'>
              <SimpleFilter
                value={filterText}
                onChangeValue={setFilterText}
              />
              <div className="counts">
                {currentAccount && (
                  <Typography>
                    {pluralize(customAminoAcids.length, 'amino acid')}
                  </Typography>
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box display='grid' justifyContent='end'>
              <AddButton
                className='add-associated-model'
                onClick={this.handleClickNew}
                text={'Create a new amino acid'}
              /><br />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const {
      accountsStore: {
        customAminoAcidsStore: {
          displayRows,
          sortBy,
          setSortBy,
          editCustomAminoAcidStore,
          customAminoAcids,
        },
      },
    } = getRootStore();

    return (
      <div className='CustomAminoAcidsPage'>
        {this.renderTopRow()}
        <SimpleDataTable
          stickyHeaders
          columns={columns}
          rows={displayRows}
          onClickRow={this.handleClickRow}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />

        <EditCustomAminoAcidDialog
          store={editCustomAminoAcidStore}
          existingResidues={customAminoAcids}
        />
      </div>
    );
  }
}
