import { useEffect, useState } from 'react';

export function usePersistentState<T extends object>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  const [state, setInternalState] = useState<T>(initialValue);

  useEffect(() => {
    const value = localStorage.getItem(key);

    if (!value) return;

    try {
      const stored = JSON.parse(value);

      // Ensure stored has all keys from initialValue and remove extra keys
      Object.keys(stored).forEach(key => {
        if (!(key in initialValue)) {
          delete stored[key];
        }
      });

      Object.entries(initialValue).forEach(([key, value]) => {
        if (!(key in stored)) {
          stored[key] = value;
        }
      });

      setInternalState(stored);
    } catch (e) {
    }
  }, [key]);

  const setState = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
    setInternalState(value);
  };

  return [state, setState];
}
