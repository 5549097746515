import { Schema } from '@/Annotator/data/Schema';
import { FieldDataType } from '@/FieldDefinitions/types';
import { SuggestionType } from '@/Annotator/data/templates';
import { EditFormDefinitionStore } from '@/FormDefinitions/stores/editFormDefinitionStore';
import { FormLayoutForEditing } from '@/FormDefinitions/types';
import { EditFormUtils } from '@/FormDefinitions/utils/editFormUtils';
import { A } from '@/shared/components/sanitizedTags.js';
import { observer } from 'mobx-react';
import React from 'react';
import { term } from '@/shared/utils/stringUtils';

type Props = {
  field: FormLayoutForEditing;
  store: EditFormDefinitionStore;
}

@observer
export class FormFieldContents extends React.Component<Props> {
  renderOntologyAssignment() {
    const { store } = this.props;

    const schema = store.currentTemplateSchema;
    const assignment = store.assignmentFromLayout(this.props.field);
    if (assignment) {
      const assn = schema.findAssignment(assignment.propURI, []);
      if (!assn) {
        return;
      }

      const plusIcon = <span className='plus-icon'>⊕</span>;

      const linkTerm = assn.suggestions == SuggestionType.Full || assn.suggestions == SuggestionType.Disabled
        ? (
          <A
            href="#"
            className="ProtocolAnnotator-buttonadd">
            {plusIcon}&nbsp;Annotation
          </A>)
        : null;
      const linkLabel = (
        <A
          href="#"
          className="ProtocolAnnotator-buttonadd">
          {plusIcon}&nbsp;Free&nbsp;Text
        </A>
      );

      return (
        <div className="ProtocolAnnotator-assignment-hbox">
          <div className="ProtocolAnnotator-flexgrow0">
            {linkTerm}
            {linkLabel}
          </div>
        </div>);
    }
  }

  renderProtocolField() {
    const { store, field } = this.props;

    let dataType: FieldDataType;
    const fieldDefinition = store.getFieldDefinition(field);
    switch (field.fieldID) {
      case 'protocol_name':
        dataType = FieldDataType.Text;
        break;
      case 'run_date':
        dataType = FieldDataType.Date;
        break;

      default:
        dataType = fieldDefinition?.data_type_name;
    }

    switch (dataType) {
      case FieldDataType.Float:
      case FieldDataType.Number:
        return <input type="number" defaultValue={field.defaultValue} />;

      case FieldDataType.Text:
      case FieldDataType.LongText:
        return <input type="text" defaultValue={field.defaultValue} />;

      case FieldDataType.Date:
        return <input type="date"></input>; // doesn't seem to be supported currently

      case FieldDataType.File:
        return <span>(file picker)</span>;

      case FieldDataType.PickList:
        return <select value={field.defaultValue ?? -1}>
          <option value={-1} />
          {fieldDefinition.pick_list_values?.map((value, i) => <option key={i} value={value.id}>{value.value}
          </option>)}
        </select>;

      case FieldDataType.BatchLink:
        return <i>{`${term('batch')} link`}</i>;
    }
    return <span className='text-entry'>Preview</span>;
  }

  render() {
    const { props: { store, field } } = this;
    const renderFieldPreview = !EditFormUtils.isSidebarItem(field);

    const textClassName = 'text text-' + (EditFormUtils.isLabel(field) ? 'label' : 'field');
    const textStyle: React.CSSProperties = {};
    if (!EditFormUtils.isLabel(field)) {
      textStyle.maxWidth = store.fieldDisplayWidth + 'px';
    }
    return (
      <span className='FormFieldContents'>
        <span className={textClassName} style={textStyle}>
          <label>
            {field.label}
          </label>
        </span>
        <span className='field'>
          {renderFieldPreview && field?.ontologyAssn ? this.renderOntologyAssignment() : null}
          {renderFieldPreview && field?.fieldID ? this.renderProtocolField() : null}
        </span>
      </span>);
  }
}
