/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */

import { OntologyTemplate } from '@/Annotator/data/templates';
import ProtocolAnnotator from '@/Annotator/Templates/ProtocolAnnotator';
import { StandardDialogActions } from '@/shared/components';
import { FieldDefinition } from '@/FieldDefinitions/types';
import { EditFormDefinitionStore } from '@/FormDefinitions/stores/editFormDefinitionStore';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { EditFieldDialog } from './EditFieldDialog';
import { FormContents } from './FormContents';
import Sidebar from './Sidebar';

type Props = {
  store: EditFormDefinitionStore;
  ontology_templates: Array<OntologyTemplate>;
  protocol_field_definitions: Array<FieldDefinition>;
};

@observer
export class EditFormDialog extends React.Component<Props> {
  readonly refContentsAndSidebar = React.createRef<HTMLDivElement>();
  readonly refContents = React.createRef<HTMLDivElement>();
  disposers: Array<() => void> = [];

  get dragStore() {
    return this.props.store.dragStore;
  }

  get isDragging() {
    return !!this.dragStore.dragState.isDragging;
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.handleResize);
    this.disposers.push(() => {
      window.removeEventListener('resize', this.handleResize);
    });

    const interval = setInterval(this.handleIntervalAutoscroll, 10);
    this.disposers.push(() => {
      clearInterval(interval);
    });
  }

  componentWillUnmount(): void {
    this.disposers.forEach(d => d());
  }

  get originalName() {
    return this.props.store.valueBeforeEdits?.name ?? '';
  }

  handleIntervalAutoscroll = () => {
    const { dragState: { isMouseDown, mouseMoveEvent } } = this.dragStore;
    if (isMouseDown && mouseMoveEvent) {
      const { clientX, clientY } = mouseMoveEvent;
      const { current } = this.refContents;
      if (current) {
        const { top, bottom, left, right } = current.getBoundingClientRect();
        const scrollSpeed = 4;
        /* eslint-disable no-nested-ternary */
        const scrollX = clientX < left ? -scrollSpeed : clientX > right ? scrollSpeed : 0;
        /* eslint-disable no-nested-ternary */
        const scrollY = clientY < top ? -scrollSpeed : clientY > bottom ? scrollSpeed : 0;
        if (scrollX || scrollY) {
          current.scrollBy(scrollX, scrollY);
          // trigger updating the position of the dragged item with new scroll position
          this.dragStore.setDragState({ ...this.dragStore.dragState });
        }
      }
    }
  };

  handleResize = () => {
    this.props.store.setFormContentWidth(this.refContents.current?.clientWidth ?? 0);
  };

  handleClose = (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'escapeKeyDown') {
      this.props.store.handleCancelEdit();
    }
  };

  get dialogTitle() {
    if (this.isEditingExisting) {
      return `Edit Form: ${this.originalName}`;
    } else {
      return 'Create Form';
    }
  }

  get value() {
    return this.props.store.value;
  }

  get isCreatingNew() {
    return !this.value?.id;
  }

  get isEditingExisting() {
    return !this.isCreatingNew;
  }

  handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.store.updateFormName(event.target.value);
    this.props.store.setFormNameError(!event.target.value);
  };

  handleSelectSection = (e, value: string) => {
    this.props.store.selectCurrentForm(value as any);
  };

  getDraggableTarget = (e: React.MouseEvent<HTMLElement>) => {
    let testTarget = e.target as HTMLElement;
    if (testTarget?.classList?.contains('fa-pencil') || testTarget?.tagName === 'BUTTON') {
      return null;
    }

    // look for draggable element
    let draggableTarget: HTMLElement;
    while (testTarget.tagName !== 'BODY') {
      if (testTarget.dataset.draggable) {
        draggableTarget = testTarget;
        break;
      }
      testTarget = testTarget.parentElement;
    }
    return draggableTarget;
  };

  handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    const draggableTarget = this.getDraggableTarget(e);

    if (draggableTarget) {
      this.dragStore.setDragState({
        contentsContainer: this.refContents.current,
        isDragging: false, // not yet, wait for mouse to move
        isMouseDown: true,
        mouseMoveEvent: null,
        currentDraggableId: draggableTarget.dataset.draggable,
        mouseDownEvent: e,
        initialScrollPosition: {
          x: this.refContents.current.scrollLeft,
          y: this.refContents.current.scrollTop,
        },
        formBounds: this.refContents.current.getBoundingClientRect(),
        draggableBounds: draggableTarget.getBoundingClientRect(),
        innerDragHtml: { __html: draggableTarget.outerHTML },
      });

      e.stopPropagation();
      e.preventDefault();
    }
  };

  handleDoubleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (target.className?.includes?.('SidebarField')) {
      this.dragStore.handleDoubleClick(e,
        this.dragStore.dragState.currentDraggableId ?? target.dataset.draggable);
    }
  };

  handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    const { dragState } = this.dragStore;
    if (dragState.isMouseDown) {
      this.dragStore.setDragState({ mouseMoveEvent: e });
      e.stopPropagation();
      e.preventDefault();
    }
  };

  handleMouseUp = e => {
    const { dragState } = this.dragStore;
    if (dragState.isMouseDown) {
      this.dragStore.setDragState({
        isMouseDown: false,
        isDragging: false,
        currentDraggableId: null,
        contentsContainer: null,
        mouseDownEvent: null,
        initialScrollPosition: null,
        formBounds: null,
        draggableBounds: null,
        innerDragHtml: null,
      });
    }
  };

  handleChangePreviewMode = () => {
    this.props.store.setPreviewMode(!this.props.store.previewMode);
  };

  handleSubmit = () => {
    const {
      props: {
        store: {
          value,
          handleSubmit,
          setFormNameError,
        },
      },
    } = this;
    if (!value.name) {
      setFormNameError(true);
    } else {
      handleSubmit();
    }
  };

  render() {
    const {
      props: {
        store,
        store: {
          value,
          isOpen,
          previewMode,
          formNameError,
          handleCancelEdit,
          handleDelete,
          currentFormName,
          availableForms,
          formContentWidth,
          setFormContentWidth,
        },
      },
      dragStore: {
        dragState: {
          isDragging,
          innerDragHtml,
        },
        dragTransform,
      },
    } = this;

    const updateFormContentWidth = () => {
      if (this.refContents.current?.clientWidth) {
        setTimeout(() => setFormContentWidth(this.refContents.current?.clientWidth ?? 0));
      } else {
        setTimeout(updateFormContentWidth);
      }
    };
    updateFormContentWidth();

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
          className='EditFormDialog'
          fullWidth
          maxWidth='lg'
          PaperProps={{
            className: 'main-dialog-paper',
          }}
        >
          {value && (
            <>
              <DialogTitle className='muiDialog-title'>
                {this.dialogTitle}
              </DialogTitle>

              <DialogContent className='dialog-contents'>

                {/* top row containing text input and section selection */}
                <Grid container direction="row" className='top-row'>

                  <Grid item xs={6}>
                    <Tabs value={currentFormName} onChange={this.handleSelectSection}>
                      {availableForms.map(section =>
                        <Tab key={section.id} value={section.id} label={section.name} />)
                      }
                    </Tabs>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      className='form-name'
                      required={true}
                      label="Form Name"
                      value={value.name ?? ''}
                      onChange={this.handleChangeName}
                      error={formNameError}
                      helperText={formNameError ? 'Form name is required' : ''}
                    />
                  </Grid>

                </Grid>

                <div className='contents-and-sidebar-container'
                  ref={this.refContentsAndSidebar}
                  onMouseDown={this.handleMouseDown}
                  onMouseMove={this.handleMouseMove}
                  onMouseUp={this.handleMouseUp}
                  onDoubleClick={this.handleDoubleClick}
                >

                  <div className='form-contents-container' ref={this.refContents}>
                    {!previewMode && formContentWidth
                      ? <div className='form-contents'>
                        <FormContents store={store} />
                      </div>
                      : null}

                    {previewMode && formContentWidth
                      ? <div className='preview-parent'>
                        <ProtocolAnnotator {...store.formPreviewProps} />
                      </div>
                      : null}
                  </div>

                  <div className='sidebar-contents-container'>
                    <div className='sidebar-contents'>
                      <Sidebar store={store} />
                    </div>
                  </div>

                  {isDragging &&
                    <div className='dragging'
                      style={dragTransform}
                      dangerouslySetInnerHTML={innerDragHtml}>
                    </div>
                  }
                </div>
              </DialogContent>
              <StandardDialogActions
                deleteProps={(value.id) ? { onClick: handleDelete } : undefined}
                cancelProps={{
                  onClick: handleCancelEdit,
                }}
                defaultButtonProps={{
                  onClick: this.handleSubmit,
                  label: 'Save',
                }} />
            </>
          )}
          <EditFieldDialog store={this.props.store} />
        </Dialog>
      </>);
  }
}
