/* eslint-disable react/forbid-dom-props */
import React from 'react';
import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import { MonomerDefinition } from './NaturalMonomers';
import { MonomerAssimilationType } from './MonomerAssimilation';
import { TabContext, TabPanel } from '@mui/lab';
import { ChemistryImage, StandardDialogActions } from '@/shared/components';

type Props = {
  isOpen: boolean;
  customPeptides: MonomerDefinition[],
  customNucleotides: MonomerDefinition[],
  handleClose: () => void;
};

type State = {
  selectedTab: MonomerAssimilationType,
};

export class CustomMonomerDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedTab: MonomerAssimilationType.Peptide,
    };
  }

  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderDialog()}
      </Dialog>
    );
  }

  private renderDialog(): JSX.Element {
    if (!this.props.isOpen) return null;

    const { customPeptides, customNucleotides } = this.props;

    const renderMonomers = (monomers: MonomerDefinition[]): JSX.Element[] => {
      monomers = monomers.sort((m1, m2) => m1.code.localeCompare(m2.code));
      const gridContent: JSX.Element[] = [];

      for (let n = 0; n < monomers.length; n++) {
        const row = n + 1;
        gridContent.push((
          <div
            key={`bg${row}`}
            className={`composeMacromol-edit-line-${row % 2 == 0 ? 'even' : 'odd'}`}
            style={{ gridArea: `${row} / start / ${row} / end` }}
            />
        ));
        gridContent.push((
          <div
            key={`code${row}`}
            className='composeMacromol-edit-item'
            style={{ gridArea: `${row} / code` }}
            >
            {monomers[n].code}
          </div>
        ));
        gridContent.push((
          <div
            key={`natural${row}`}
            className='composeMacromol-edit-item'
            style={{ gridArea: `${row} / natural` }}
            >
            {monomers[n].natural}
          </div>
        ));
        gridContent.push((
          <div
            key={`mol${row}`}
            className='composeMacromol-edit-item'
            style={{ gridArea: `${row} / molecule` }}
            >
            <ChemistryImage
              key={`mol${row}`}
              src={monomers[n].molfile}
              width={150}
              height={100}
              displayRLabels={true}
              />
          </div>
        ));
      }

      if (gridContent.length == 0) {
        gridContent.push((
          <div key="empty" style={{ gridArea: '1 / code / 1 / end' }}>
            No monomers currently defined.
          </div>
        ));
      }

      return gridContent;
    };

    const gridPeptides = renderMonomers(customPeptides);
    const gridNucleotides = renderMonomers(customNucleotides);

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          View Custom Monomers
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          <TabContext value={this.state.selectedTab}>
            <div className="composeMacromol-tabbar">
              <div>
                <Tabs value={this.state.selectedTab} onChange={this.handleChangeTab}>
                  <Tab key={MonomerAssimilationType.Peptide} label="Peptides" value={MonomerAssimilationType.Peptide}/>
                  <Tab key={MonomerAssimilationType.Nucleotide} label="Nucleotides" value={MonomerAssimilationType.Nucleotide}/>
                </Tabs>
              </div>
            </div>

            <TabPanel value={MonomerAssimilationType.Peptide}>
              <div className="composeMacromol-edit-grid">
                {gridPeptides}
              </div>
            </TabPanel>
            <TabPanel value={MonomerAssimilationType.Nucleotide}>
              <div className="composeMacromol-edit-grid">
                {gridNucleotides}
              </div>
            </TabPanel>
          </TabContext>
        </DialogContent>

        <StandardDialogActions className="project__action"
          defaultButtonProps={{
            onClick: this.props.handleClose,
            label: 'Close',
          }}
        />
      </>
    );
  }

  private handleChangeTab = (_event: React.ChangeEvent, val: string) => {
    this.setState({ selectedTab: val as MonomerAssimilationType });
  };
}
