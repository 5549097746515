import { ensureChemUtils } from '@/shared/utils/chemUtils';

export type MoleculeProperties = { [name: string]: number };

export const molecule_properties = [
  { 'label': 'Molecular weight', 'short': 'MW', 'key': 'molWeight', 'precision': 3 },
  { 'label': 'LogD', 'short': 'LogD', 'key': 'logD', 'precision': 1 },
  { 'label': 'LogP', 'short': 'LogP', 'key': 'logP', 'precision': 1 },
  { 'label': 'LogS', 'short': 'LogS', 'key': 'logS', 'precision': 1 },
  { 'label': 'H-bond donors', 'short': 'HBD', 'key': 'hBondDonors', 'precision': 0 },
  { 'label': 'H-bond acceptors', 'short': 'HBA', 'key': 'hBondAcceptors', 'precision': 0 },
  { 'label': 'Rotatable bonds', 'short': 'RB', 'key': 'rotatableBonds', 'precision': 0 },
  { 'label': 'Topological polar surface area', 'short': 'TPSA', 'key': 'polarSurfaceArea', 'precision': 1 },
  { 'label': 'CNS MPO', 'short': 'CNS-MPO', 'key': 'cnsMpo', 'precision': 1 },
];
export const all_properties = molecule_properties.map((property) => property.key);

export class PropertyCalculator {
  chemUtils: ChemUtils | null = null;
  _properties: string;
  constructor(properties: string[] = null) {
    this.chemUtils = null;
    properties = (properties || all_properties).filter((p) => all_properties.includes(p));
    this._properties = JSON.stringify(properties);
  }

  async is_ready(): Promise<boolean> {
    this.chemUtils = await ensureChemUtils();
    return !!this.chemUtils;
  }

  predict(structure: string): MoleculeProperties {
    if (!this.chemUtils) {
      return {};
    }
    return JSON.parse(this.chemUtils.calcProperties(structure, this._properties));
  }
}

export const propertyByKey = (key: string) => molecule_properties.find((p) => p.key === key);
