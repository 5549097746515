import { HasIdAndName } from '@/Accounts/types';
import { RunData } from '@/RunData/types';
import { ColumnDef, ColumnSortDef } from '@/shared/components';
import { ColumnsEditor } from '@/shared/components/ColumnsEditor/ColumnsEditor';
import MultipleTablesWithStickyHeaders from '@/shared/components/MultipleTablesWithStickyHeaders/MultipleTablesWithStickyHeaders';
import { A, Img } from '@/shared/components/sanitizedTags';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { AnyObject, StringOrNumber } from '@/types';
import heatMapIcon from 'ASSETS/images/cdd30/icons/heatmap.png';
import magnifierIcon from 'ASSETS/images/cdd30/icons/magnifier.png';
import groupBy from 'lodash/groupBy';
import { observer } from 'mobx-react';
import React from 'react';

type Props = {
  columns: Array<ColumnDef>;
  availableColumns: Array<ColumnDef>;
  rows: Array<RunData>;
  sortBy?: ColumnSortDef;
  setSortBy?: (sorting: ColumnSortDef) => void;
  setVisibleColumns: (value: Array<StringOrNumber>) => void;
  visibleColumns: Array<StringOrNumber>;
};

@observer
export default class RunDataTables extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expandedState: {},
    };
  }

  getRowHref = (run: RunData) => {
    return run.run_link;
  };

  renderCustomHeaderCell = (column: ColumnDef) => {
    if (column.id === 'btn_edit_columns') {
      return <ColumnsEditor
        availableColumns={this.props.availableColumns}
        value={this.props.visibleColumns}
        onChangeValue={this.props.setVisibleColumns}
      />;
    }
  };

  renderCell = (columnId: StringOrNumber, run: RunData) => {
    const cellData = run[columnId];
    if (cellData && typeof cellData === 'object' && !React.isValidElement(cellData)) {
      const { file_name, display_name, value, url } = cellData as AnyObject;
      const display = file_name ?? display_name ?? value;
      if (url) {
        const target = url.includes('#molecule-batches') ? '_blank' : undefined;
        return <A href={url} target={target}>{display}</A>;
      }
      return display;
    }

    switch (columnId) {
      case 'run_date':
        return <span className="pseudo-link" id={`link_to_run_${run.id}`}>{run.run_date}</span>;

      case 'molecules':
        return (run.num_compounds === 0)
          ? '0'
          : <A href={run.compounds_search_path}>{run.num_compounds} <Img
            width={16}
            height={16}
            className='icon-16'
            alt='compounds'
            src={magnifierIcon}
          />
          </A>;

      case 'plates': {
        const plateCount = (run.num_plates > 0 && run.has_heatmaps) ? run.num_plates : 0;
        return (plateCount === 0)
          ? '0'
          : <A href={run.plates_path}>{plateCount} <Img
            width={16}
            height={16}
            className='icon-16'
            alt='plates'
            src={heatMapIcon}
          />
          </A>;
      }
    }

    return null;
  };

  render() {
    const { rows } = this.props;

    const sectionMap = groupBy(rows, (item) => item.project.id);
    const projectMap = rows.reduce((acc, row) => {
      acc[row.project.id] = row.project;
      return acc;
    }, {});

    const tables: Array<{
      group: HasIdAndName;
      rows: Array<AnyObject>;
    }> = keysAsNumbers(sectionMap).map(projectId => ({
      group: projectMap[projectId],
      rows: sectionMap[projectId],
    }));

    return <MultipleTablesWithStickyHeaders
      {...this.props}
      tables={tables}
      getRowHref={this.getRowHref}
      renderCustomHeaderCell={this.renderCustomHeaderCell}
      renderCell={this.renderCell}
    />;
  }
}
