import { LocationNode, LocationValue, Sample } from '@/Samples/types';
import { TreeView } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LocationPickerTreeItem } from './LocationPickerTreeItem';
import { LocationBoxPicker } from './LocationBoxPicker';
import { LocationUtils } from './locationUtils';
import { StringOrNumber } from '@/types';

type Props = {
  value?: LocationValue;
  autoSelectBox?: boolean;
  locations: Array<LocationNode>;
  changeLegend?: React.ReactElement;
  onChange: (value: LocationValue) => void;
  fetchBoxContents: (boxId: StringOrNumber) => Promise<Array<Sample>>;
};

type State = {
  selectedTreeNode?: LocationNode;
  expanded?: Array<string>;
  loading?: boolean;
  samplesInBox?: Array<Sample>;
};

export class LocationPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.computeState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.locations !== this.props.locations) {
      this.setState(this.computeState());
    }
  }

  computeState() {
    const { locations, value, autoSelectBox = true } = this.props;
    const newState: State = { ...this.state };

    if (locations && locations.length) {
      newState.selectedTreeNode =
        value?.id ? LocationUtils.findSelectedLocationNode(value?.id, locations) : null;

      newState.expanded = value?.id
        ? LocationUtils.getExpandedNodes(value, locations)
        : LocationUtils.getAllExpandedNodes(locations);

      if (autoSelectBox && !newState.selectedTreeNode) {
        // automatically select the first box if none is selected
        newState.selectedTreeNode = LocationUtils.findFirstBox(locations[0]) ?? locations[0];
      }
    }

    if (newState.selectedTreeNode && newState.selectedTreeNode !== this.state?.selectedTreeNode) {
      setTimeout(() => this.fetchBoxContentsOnChange(), 0);
    }
    return newState;
  }

  fetchBoxContentsOnChange() {
    const node = this.state.selectedTreeNode;
    if (node?.position_limit) {
      // it's a box, so fetch the samples
      this.setState({ loading: true, samplesInBox: [] });
      this.props.fetchBoxContents(node.id).then(samples => {
        this.setState({ samplesInBox: samples, loading: false });
      });
    }
  }

  componentDidMount() {
    this.fetchBoxContentsOnChange();
  }

  handleNodeSelectEvent = (_event: React.ChangeEvent, value: string) => {
    const { autoSelectBox = true, locations } = this.props;
    let selectedTreeNode = LocationUtils.findSelectedLocationNode(value, locations);
    if (autoSelectBox && selectedTreeNode && !selectedTreeNode.position_limit) {
      // automatically select the first box if none is selected
      selectedTreeNode = LocationUtils.findFirstBox(selectedTreeNode) ?? selectedTreeNode;
    }
    if (selectedTreeNode !== this.state.selectedTreeNode) {
      this.setState({
        selectedTreeNode,
      }, this.fetchBoxContentsOnChange);

      if (selectedTreeNode.organized === false) {
        this.handleChange({
          id: selectedTreeNode.id,
          position: '',
          value: selectedTreeNode.value,
        });
      }
    }
  };

  handleNodeToggle = (event: React.ChangeEvent, nodeIds: Array<string>) => {
    const { type, target } = event.nativeEvent;

    if (type === 'click' && nodeIds.length < this.state.expanded.length) {
      // the MUI tree wants to collapse when clicking on an item. To override this, when the node is collapsing
      // (reducing the number of expanded nodes) in response to a click that's NOT on the icon, ignore it.
      const tagName = (target as HTMLElement).tagName;
      if (tagName !== 'svg' && tagName !== 'path') {
        return;
      }
    }
    this.setState({
      expanded: nodeIds,
    });
  };

  handleChange = (value: LocationValue) => {
    this.props.onChange(value);
  };

  render() {
    const { locations, value, changeLegend } = this.props;
    const { loading, samplesInBox, selectedTreeNode } = this.state;

    return (
      <Stack
        direction="row"
        spacing={4}
        sx={{ width: '100%', height: '100%' }}
        className='LocationPicker'
      >
        <Box className='tree-container' sx={{ width: '20rem', padding: 2 }}>
          <TreeView
            aria-label="location picker"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: '100%', flexGrow: 1, width: 250, maxWidth: 400, overflowY: 'auto' }}
            selected={'' + (selectedTreeNode?.id ?? value?.id ?? '')}
            expanded={this.state.expanded}
            onNodeToggle={this.handleNodeToggle}
            onNodeSelect={this.handleNodeSelectEvent}
          >
            {locations.map(location =>
              <LocationPickerTreeItem
                key={location.id}
                node={location}
                expanded={this.state.expanded} />,
            )}
          </TreeView>
        </Box>

        <Stack
          sx={{ flex: 1, padding: 2, overflow: 'auto' }}
          spacing={2}
        >
          <Box className='LocationPicker'>
            <LocationBoxPicker
              value={value}
              box={this.state.selectedTreeNode}
              onChange={this.handleChange}
              loading={loading}
              samples={samplesInBox}
            />
          </Box>
          {changeLegend && <span className='change-legend'>
            {changeLegend}
          </span>}
        </Stack>
      </Stack>
    );
  }
}
