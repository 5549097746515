import { propertyByKey } from './calculator';

export type PropertySort = {
  sortKey: string;
  order: 'asc' | 'desc' | 'none';
};

export const noSort: PropertySort = { sortKey: '', order: 'none' };

export const newSort = (sortKey: string): PropertySort => {
  return { sortKey, order: 'asc' };
};

export const toggleSort = (currentSort: PropertySort, newSortKey: string): PropertySort => {
  if (currentSort.sortKey === newSortKey) {
    switch (currentSort.order) {
      case 'asc':
        return { sortKey: newSortKey, order: 'desc' };
      case 'desc':
        return noSort;
      case 'none':
        return newSort(newSortKey);
    }
  }
  return { sortKey: newSortKey, order: 'asc' };
};

export const isSorted = (sortBy: PropertySort): boolean => {
  return sortBy && sortBy.order !== 'none' && sortBy.sortKey !== 'none';
};

export const describeSortOrder = (sortBy: PropertySort | undefined): string | JSX.Element => {
  if (!sortBy) {
    return null;
  }
  if (sortBy.sortKey == noSort.sortKey) {
    return 'Sorted by Tanimoto similarity';
  }
  return `Sorted by ${propertyByKey(sortBy.sortKey)?.label}`;
};
