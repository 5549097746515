import * as React from 'react';
import autobind from 'class-autobind';
import { AddButton, DownloadButton, SimpleFilter } from '@/shared/components';
import { Minidot } from '@/shared/components/Minidot';
import { observer } from 'mobx-react';
import { getRootStore } from '../../stores/rootStore';
import { EditUserDialog } from './components/EditUserDialog';
import { Box, Grid, Typography } from '@mui/material';
import { UsersTable } from './components/UsersTable';
import { UserSchema } from '../types';
import { pluralize } from '@/shared/utils/stringUtils';
import './UsersPage.sass';
import { accountsService } from '@/Accounts/accountsServices';
import { CDD } from '@/typedJS';

@observer
export class UsersPage extends React.Component {
  constructor(props: never) {
    super(props);
    autobind(this);
  }

  handleClickNew() {
    const {
      accountsStore: {
        usersStore: { editUserStore },
      },
    } = getRootStore();
    editUserStore.handleNewUser();
  }

  handleClickRow(user: UserSchema) {
    if (user) {
      const {
        accountsStore: {
          usersStore: { editUserStore },
        },
      } = getRootStore();

      editUserStore.handleEditUser(user);
    }
  }

  downloadCsv() {
    accountsService.getAccountExportUsers().then(response => {
      CDD.insertGlobalMessage(response.data.message);
      CDD.Pollers.start('export', `/vaults/${response.data.vault}/export_progress`, 2);
    });
  }

  renderTopRow() {
    const {
      accountsStore: {
        currentAccount,
        usersStore: {
          filterText, setFilterText,
        },
      },
    } = getRootStore();
    return (
      <div className='top-row'>
        <Grid container alignItems='center'>
          <Grid item xs={5}>
            <Box display='flex' justifyContent='left' alignItems='center'>
              <SimpleFilter
                value={filterText}
                onChangeValue={setFilterText}
              />
              <div className="counts">
                {currentAccount && (
                  <Typography>
                    {pluralize(currentAccount.users_count, 'user')}
                    <Minidot />
                    {pluralize(currentAccount.licenses, 'license')}
                  </Typography>
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box display='grid' justifyContent='end'>
              <AddButton
                className='add-associated-model'
                onClick={this.handleClickNew}
                text={'Create a new user'}
              /><br />
              <DownloadButton
                className='add-associated-model'
                onClick={this.downloadCsv}
                text={'Export account members (csv)'}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const {
      accountsStore: {
        usersStore: {
          displayRows,
          sortBy,
          setSortBy,
          editUserStore,
        },
      },
    } = getRootStore();

    return (
      <div className='UsersPage'>
        {this.renderTopRow()}
        <UsersTable
          rows={displayRows}
          onClickRow={this.handleClickRow}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />

        <EditUserDialog
          store={editUserStore}
        />
      </div>
    );
  }
}
