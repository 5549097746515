import { Sample } from '@/Samples/types';
import { Tooltip } from '@mui/material';
import React from 'react';

type TwoDimensional = {
  x: number;
  y: number;
};

type Props = {
  cellSize: number;
  cellPadding: number;
  position: number;
  selected: boolean;
  sample: Sample;
  onClick: (position: number) => void;
  index: TwoDimensional;
  boxSize: TwoDimensional;
};

export class LocationBoxPosition extends React.Component<Props> {
  handleClick = () => {
    if (!this.props.sample) {
      this.props.onClick(this.props.position);
    }
  };

  render() {
    const {
      cellSize,
      cellPadding,
      position,
      selected,
      sample,
      index,
      boxSize,
    } = this.props;
    let className = `box-position-element ${
      selected ? 'box-position-selected' : ''
    }`;
    className += sample ? ' box-position-filled' : ' box-position-empty';
    className += index.x === 0 ? ' box-position-left-edge' : '';
    className += index.y === boxSize.y - 1 ? ' box-position-bottom-edge' : '';

    const fontSize = Math.min(16, cellSize / 2);
    const element = (
      <div
        className={className}
        onClick={this.handleClick}
        style={{ width: cellSize, height: cellSize, margin: 0 }}
      >
        <label style={{ fontSize: fontSize / 16 + 'rem' }}>{position}</label>
      </div>
    );
    if (sample) {
      return <Tooltip title={sample.name}>{element}</Tooltip>;
    }
    return element;
  }
}
