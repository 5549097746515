import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { OntologyTemplate, TemplateGroup, TemplateAssignment, TemplateValue } from '../data/templates';
import './Template.sass';
import { TemplateComponentEditType } from './TemplateComponentDialog';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { StandardDialogActions } from '@/shared/components';

type Props = {
  isOpen: boolean,
  dataType: TemplateComponentEditType,
  initText: string,
  entity: TemplateGroup | TemplateAssignment | TemplateValue,
  template: OntologyTemplate,
  onDialogCancel: () => void,
  onDialogPaste: (text: string) => void,
};

type State = {
  currentText: string,
};

export class ClipboardDialog extends React.Component<Props, State> {
  private lastOpen = false;

  constructor(props) {
    super(props);
    this.state = {
      currentText: props.initText,
    };
  }

  get dialogTitle() {
    const { dataType, entity } = this.props;
    if (dataType == null) return '';

    const TYPENAMES = {
      [TemplateComponentEditType.Root]: 'Root',
      [TemplateComponentEditType.Group]: 'Group',
      [TemplateComponentEditType.Assignment]: 'Assignment',
      [TemplateComponentEditType.Value]: 'Value',
    };
    return `${TYPENAMES[dataType]} Clipboard: ${entity.name}`;
  }

  public render() {
    const { isOpen } = this.props;
    if (this.lastOpen != isOpen) {
      this.lastOpen = isOpen;
      if (isOpen) {
        setTimeout(() => this.setState({ currentText: this.props.initText }));
      }
    }

    return (
      <>
        <Dialog
          open={this.props.isOpen}
          onClose={this.props.onDialogCancel}
          className='EditTeamDialog edit-account-object-dialog'
          PaperProps={{ className: 'main-dialog-paper' }}
        >
          {this.renderDialog()}
        </Dialog>
      </>
    );
  }

  private renderDialog(): JSX.Element {
    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          {this.renderContent()}
        </DialogContent>

        <StandardDialogActions
          cancelProps={{
            onClick: this.handleCancel,
          }}
          defaultButtonProps={{
            onClick: this.handleSubmit,
            label: 'Paste',
          }}
        />
      </>
    );
  }

  private renderContent(): JSX.Element {
    if (!this.props.isOpen) return null;

    return (
      <>
        <textarea
          className="OntologyComponent-textarea resizable OntologyTemplate-clipboardtext"
          cols={40}
          rows={6}
          value={this.state.currentText}
          onChange={this.handleChangeText}
          autoFocus={true}
          spellCheck={false}
        />
      </>
    );
  }

  private handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ currentText: event.target.value });
  };

  private handleSubmit = (): void => {
    const { currentText } = this.state;
    if (!currentText) {
      ModalUtils.showModal('Text is blank.', {});
    }
    try {
      JSON.parse(currentText);
    } catch (ex) {
      ModalUtils.showModal('Text is not valid JSON.', {});
      return;
    }

    this.props.onDialogPaste(currentText);
  };

  private handleCancel = (): void => {
    this.props.onDialogCancel();
  };
}
