import { ExpandMoreButton } from '@/shared/components/ExpandMoreButton';
import { Backdrop, Box, CircularProgress, Collapse, Paper, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import CDDIcon from '@/shared/components/Icon.jsx';

export const ExpandableGroup = (props: {
  groupNode: ReactNode;
  group: ReactNode[];
  groupCaption?: string | ReactNode;
}) => {
  const { groupNode, group, groupCaption } = props;
  const [expanded, setExpanded] = React.useState(true);
  const caption =
    groupCaption ||
    `${group.length} ${group.length == 1 ? 'structure' : 'structures'}`;
  return (
    <Stack direction='row' spacing={2} alignItems='start'>
      {groupNode}
      <ExpandMoreButton
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      />
      <Stack direction='column'>
        <Box style={{ fontWeight: 'bold' }}>{caption}</Box>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Box
            style={{ display: 'flex' }}
            sx={{ flexWrap: 'wrap' }}
            aria-label='scaffold-group'
          >
            {group}
          </Box>
        </Collapse>
      </Stack>
    </Stack>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CenterContent = (content: any) => {
  return (
    <Box display='flex' height='100%'>
      <Box m='auto' paddingLeft='200px' paddingRight='200px'>
        {content}
      </Box>
    </Box>
  );
};

export const Overlay = (props: { children: ReactNode, zIndex?: number, height?: number | string }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: props.height || '100%',
        zIndex: props.zIndex || 'auto',
      }}
    >
      {props.children}
    </Box>
  );
};

export const OverlayTopLeft = (props: { children: ReactNode, zIndex?: number }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: props.zIndex || 'auto',
      }}
    >
      {props.children}
    </Box>
  );
};

export const OverlayMessageSpinner = (props: { message?: string }) => {
  return (
    <Backdrop
      open={true}
      sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 10 })}
    >
      <Paper
        elevation={4}
        sx={{
          padding: '1.5rem',
          fontSize: '1rem',
          textAlign: 'center',
          minWidth: '15rem',
        }}
        data-testid='message-spinner'>
        <CDDIcon icon='spinner' style={{ marginRight: '1rem', width: '1rem', height: '1rem' }} />
        {props.message || 'Loading...'}
      </Paper>
    </Backdrop >
  );
};

export const delay = (ms: number) => new Promise(_resolve => setTimeout(_resolve, ms));
