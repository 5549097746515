import React from 'react'
import { Card, CardMedia } from '@mui/material'
import { ChemistryImage } from '@/shared/components'

export default class Structures extends React.Component {
  renderStructureImage(structureData) {
    const { mrv, molfile, imagePath, highlightedStructures, isRegistration } = structureData

    return <ChemistryImage
      className='eln_entries_thumbnail'
      src={mrv || molfile}
      imgUrl={imagePath}
      highlightedStructures={highlightedStructures}
      isRegistration={isRegistration}
      minWidth="150"
      minHeight="150"
    />
  }

  render() {
    const { structures, displayStructureRow } = this.props

    if (structures.size === 0) {
      return null
    }

    return (
      <div className="search_results__structures">
        {structures.map((structureData, i) => {
          let listName
          const { name, className, isMatching, molfile } = structureData

          if (!(displayStructureRow || isMatching)) {
            return null
          }

          if (name.length) {
            listName = (
              <div className="search_results__molecule_name">
                <span className="search_results__value"
                  dangerouslySetInnerHTML={{ __html: name }}
                >
                </span>
              </div>
            )
          }

          const cardClassName = `${className} search_results__molecule_new_image_service`
          return (
            <div
              className="search_results__molecule"
              key={i}
            >
              {listName}
              <Card className={cardClassName}>
                {this.renderStructureImage(structureData)}
              </Card>
            </div>
          )
        }).toArray()}
      </div>
    )
  }
}
