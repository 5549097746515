import A_Alanine from 'ASSETS/data/monomers/aa_A_Alanine.mol';
import R_Arginine from 'ASSETS/data/monomers/aa_R_Arginine.mol';
import N_Asparagine from 'ASSETS/data/monomers/aa_N_Asparagine.mol';
import D_AsparticAcid from 'ASSETS/data/monomers/aa_D_AsparticAcid.mol';
import C_Cysteine from 'ASSETS/data/monomers/aa_C_Cysteine.mol';
import Q_Glutamine from 'ASSETS/data/monomers/aa_Q_Glutamine.mol';
import E_GlutamicAcid from 'ASSETS/data/monomers/aa_E_GlutamicAcid.mol';
import G_Glycine from 'ASSETS/data/monomers/aa_G_Glycine.mol';
import H_Histidine from 'ASSETS/data/monomers/aa_H_Histidine.mol';
import I_Isoleucine from 'ASSETS/data/monomers/aa_I_Isoleucine.mol';
import L_Leucine from 'ASSETS/data/monomers/aa_L_Leucine.mol';
import K_Lysine from 'ASSETS/data/monomers/aa_K_Lysine.mol';
import M_Methionine from 'ASSETS/data/monomers/aa_M_Methionine.mol';
import F_Phenylalanine from 'ASSETS/data/monomers/aa_F_Phenylalanine.mol';
import P_Proline from 'ASSETS/data/monomers/aa_P_Proline.mol';
import S_Serine from 'ASSETS/data/monomers/aa_S_Serine.mol';
import T_Threonine from 'ASSETS/data/monomers/aa_T_Threonine.mol';
import W_Tryptophan from 'ASSETS/data/monomers/aa_W_Tryptophan.mol';
import Y_Tyrosine from 'ASSETS/data/monomers/aa_Y_Tyrosine.mol';
import V_Valine from 'ASSETS/data/monomers/aa_V_Valine.mol';

import R_Ribose from 'ASSETS/data/monomers/nuc_R_Ribose.mol';
import dR_Deoxyribose from 'ASSETS/data/monomers/nuc_dR_Deoxyribose.mol';
import P_Phosphate from 'ASSETS/data/monomers/nuc_P_Phosphate.mol';
import A_Adenine from 'ASSETS/data/monomers/nuc_A_Adenine.mol';
import C_Cytosine from 'ASSETS/data/monomers/nuc_C_Cytosine.mol';
import G_Guanine from 'ASSETS/data/monomers/nuc_G_Guanine.mol';
import T_Thymine from 'ASSETS/data/monomers/nuc_T_Thymine.mol';
import U_Uracil from 'ASSETS/data/monomers/nuc_U_Uracil.mol';

export interface MonomerDefinition {
  name: string;
  code: string; // longer code (3 letters for natural amino acids, sometimes more for unnaturals)
  natural: string; // single letter natural amino acid code
  molfile: string;
  match?: string;
}

export const NATURAL_PEPTIDES: MonomerDefinition[] = [
  {
    name: 'Alanine',
    code: 'Ala',
    natural: 'A',
    molfile: A_Alanine,
  },
  {
    name: 'Arginine',
    code: 'Arg',
    natural: 'R',
    molfile: R_Arginine,
  },
  {
    name: 'Asparagine',
    code: 'Asn',
    natural: 'N',
    molfile: N_Asparagine,
  },
  {
    name: 'Aspartic acid',
    code: 'Asp',
    natural: 'D',
    molfile: D_AsparticAcid,
  },
  {
    name: 'Cysteine',
    code: 'Cys',
    natural: 'C',
    molfile: C_Cysteine,
  },
  {
    name: 'Glutamine',
    code: 'Gln',
    natural: 'Q',
    molfile: Q_Glutamine,
  },
  {
    name: 'Glutamic acid',
    code: 'Glu',
    natural: 'E',
    molfile: E_GlutamicAcid,
  },
  {
    name: 'Glycine',
    code: 'Gly',
    natural: 'G',
    molfile: G_Glycine,
  },
  {
    name: 'Histidine',
    code: 'His',
    natural: 'H',
    molfile: H_Histidine,
  },
  {
    name: 'Isoleucine',
    code: 'Ile',
    natural: 'I',
    molfile: I_Isoleucine,
  },
  {
    name: 'Leucine',
    code: 'Leu',
    natural: 'L',
    molfile: L_Leucine,
  },
  {
    name: 'Lysine',
    code: 'Lys',
    natural: 'K',
    molfile: K_Lysine,
  },
  {
    name: 'Methionine',
    code: 'Met',
    natural: 'M',
    molfile: M_Methionine,
  },
  {
    name: 'Phenylalanine',
    code: 'Phe',
    natural: 'F',
    molfile: F_Phenylalanine,
  },
  {
    name: 'Proline',
    code: 'Pro',
    natural: 'P',
    molfile: P_Proline,
  },
  {
    name: 'Serine',
    code: 'Ser',
    natural: 'S',
    molfile: S_Serine,
  },
  {
    name: 'Threonine',
    code: 'Thr',
    natural: 'T',
    molfile: T_Threonine,
  },
  {
    name: 'Tryptophan',
    code: 'Trp',
    natural: 'W',
    molfile: W_Tryptophan,
  },
  {
    name: 'Tyrosine',
    code: 'Tyr',
    natural: 'Y',
    molfile: Y_Tyrosine,
  },
  {
    name: 'Valine',
    code: 'Val',
    natural: 'V',
    molfile: V_Valine,
  },
];

export const NATURAL_NUCLEOTIDES: MonomerDefinition[] = [
  {
    name: 'Ribose',
    code: 'R',
    natural: 'R',
    molfile: R_Ribose,
  },
  {
    name: 'Deoxyribose',
    code: 'dR',
    natural: 'dR',
    molfile: dR_Deoxyribose,
  },
  {
    name: 'Phosphate',
    code: 'P',
    natural: 'P',
    molfile: P_Phosphate,
  },
  {
    name: 'Adenine',
    code: 'A',
    natural: 'A',
    molfile: A_Adenine,
  },
  {
    name: 'Cytosine',
    code: 'C',
    natural: 'C',
    molfile: C_Cytosine,
  },
  {
    name: 'Guanine',
    code: 'G',
    natural: 'G',
    molfile: G_Guanine,
  },
  {
    name: 'Thymine',
    code: 'T',
    natural: 'T',
    molfile: T_Thymine,
  },
  {
    name: 'Uracil',
    code: 'U',
    natural: 'U',
    molfile: U_Uracil,
  },
];
