import { deepClone } from '@/Annotator/data/utils';
import { BuiltResultsCache, DataOptions } from './DataOptions';
import axios from 'axios';
import { activeAjax } from '@/typedJS';

type Extern = any; // eslint-disable-line @typescript-eslint/no-explicit-any
declare let Pollers: Extern;

export class SubmitComposition {
  constructor(
    private columnTitles: string[],
    private rowData: string[][],
    private dataOptions: DataOptions,
    private builtResultsCache: BuiltResultsCache[]) {
  }

  public execute(): void {
    let columnTitles = [...this.columnTitles];
    const rowData = deepClone(this.rowData);
    const { dataOptions, builtResultsCache } = this;

    columnTitles.splice(dataOptions.sequenceColumn, 1);
    for (const row of rowData) {
      row.splice(dataOptions.sequenceColumn, 1);
    }

    const MOLECULE_COLUMN = 'Molfile';

    columnTitles = [MOLECULE_COLUMN, ...columnTitles];
    for (let n = 0; n < builtResultsCache.length; n++) {
      rowData[n] = [builtResultsCache[n].molfile, ...rowData[n]];
    }

    const escapeCell = (cell) => {
      if (!cell.includes('"') && !cell.includes(',') && !cell.includes('\n')) return cell;
      return '"' + cell.replace(/"/g, '""') + '"';
    };
    const squishRow = (row) => {
      return row.map((cell) => escapeCell(cell)).join(',');
    };

    const table = [columnTitles, ...rowData];
    const csv = table.map((row) => squishRow(row)).join('\n');

    const form = document.getElementById('composeMacromols_form') as HTMLFormElement;
    const form_data = new FormData(form);
    form_data.set('data_file[file_data]', csv);
    const url = new URL(form.action);
    url.searchParams.set('format', 'json');

    axios({ 'url': url.toString(), 'method': 'post', 'data': form_data, 'headers': { 'Content-Type': 'application/json' } }).then(response => {
      this.waitForDataFileParsing(response.data);
    }).catch(error => {
      console.log(error);
    });
  }

  private waitForDataFileParsing(dataFile: Extern): void {
    Pollers.start('dataFileWatcher', dataFile['structure-url'], 2, this.slurpReady);
  }

  public slurpReady(slurp: Extern): void {
    Pollers.stop('dataFileWatcher');
    activeAjax.stop();
    window.location.href = slurp.url + '/mapping/edit';
  }
}
