import { openCDDElnStyleStructureEditor } from '@/Eln/Entry/components/Editor/utils/openStructureEditor';
import { StructureFormat } from '@/shared/components/StructureEditor/StructureEditorTypes';
import { MoleculeStream } from 'webmolkit/io/MoleculeStream';
import { MolUtil } from 'webmolkit/mol/MolUtil';

export const launchStructureEditor = (inputMolFile: string,
  onChange: (newMolFile: string, newMolecularFormula: string, newMolecularWeight: number) => void,
  onCancel?: () => void) => {
  openCDDElnStyleStructureEditor(inputMolFile, StructureFormat.MOL, true)
    .then((data) => {
      const newMolFile = data.mrv;
      let newMolecularWeight = 0;
      let newMolecularFormula = '';
      if (newMolFile) {
        const mol = MoleculeStream.readUnknown(newMolFile);
        newMolecularFormula = MolUtil.molecularFormula(mol);
        newMolecularWeight = MolUtil.molecularWeight(mol);
        newMolecularWeight = Math.round(newMolecularWeight * 100) / 100;
      }
      onChange(newMolFile, newMolecularFormula, newMolecularWeight);
    }, (e) => {
      console.dir(e);
      switch (e.message) {
        case 'empty':
          onChange('', '', 0);
          break;

        case 'cancel':
          onCancel?.();
      }
    });
};
