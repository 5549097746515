import React, { type FC, useCallback, useMemo } from 'react';
import {
  FilePreviewDialog,
  FilePreviewContainer,
  FilePreviewDialogFileType,
} from '@cdd/ui-kit/lib/components/composite/filePreviewDialog/v2';
import { Box } from '@cdd/ui-kit/lib/components/layout/box/v1';
import { ShouldRender } from '@cdd/ui-kit/lib/components/conditionals/ShouldRender/v1';
import { ThemeProvider } from '@cdd/ui-kit/lib/theme/provider';
import { theme } from '@cdd/ui-kit/lib/theme';
import styled from '@emotion/styled';
import axios from 'axios';

const ELN_3DMOL_EXTENSIONS = ['pdb', 'cif', 'mcif', 'mmcif', 'mol2'];

const $Box = styled(Box)({
  height: 'calc(100vh - 35px)',
  width: '100%',

  '> div:first-child': {
    width: '100%',
    height: '100%',
  },
});

export interface UploadedFile {
  id: number;
  user_id: number;
  name: string;
  size: number;
  state: string;
  failed_thumbnails_save: boolean;
  original_file_id?: number;
}

export const FilePreviewInner: FC<{
  open: boolean;
  onClose: () => void;
  file: UploadedFile;
  href: string;
  extension: string;
  format: string;
  largeThumbnailHref?: string;
  thumbnailsAllowed?: boolean;
  contentAsBase64?: string;
  allowsDownload?: boolean;
  withDialog?: boolean;
}> = ({
  open,
  onClose,
  file,
  href,
  thumbnailsAllowed,
  largeThumbnailHref,
  extension,
  contentAsBase64,
  format,
  allowsDownload = true,
  withDialog,
}) => {
  const fileType = useMemo(() => {
    if (ELN_3DMOL_EXTENSIONS.includes(extension)) {
      return 'pdb';
    }

    if (thumbnailsAllowed && extension !== 'pdf') {
      return 'image';
    }

    if (thumbnailsAllowed && extension === 'pdf') {
      return 'pdf';
    }
  }, [extension, thumbnailsAllowed]);

  const fileSrc = useMemo(() => {
    switch (fileType) {
      case 'image': {
        if (extension === 'tiff') {
          return largeThumbnailHref;
        }

        return href;
      }

      case 'pdb': {
        return contentAsBase64;
      }

      case 'pdf': {
        return href;
      }
    }
  }, [extension, largeThumbnailHref, href, fileType, contentAsBase64]);

  const getFileSrcAsync = useCallback(
    async (url: string, type: FilePreviewDialogFileType) => {
      if (type !== 'pdf') {
        return { fileSrc: url };
      }

      try {
        const response = await axios.get<string>(`${href}?no_redirect=true`);
        const dataUrl = new URL(response.data);

        return {
          fileSrc: new URL(
            `/pdf_preview?file=${response.data}&origin=${window.location.origin}`,
            dataUrl,
          ).toString(),
          origin: response.data,
        };
      } catch {
        return null;
      }
    },
    [],
  );

  const shouldRenderDialog = withDialog && !!fileType && !!fileSrc;

  return (
    <ThemeProvider theme={theme}>
      <ShouldRender shouldRender={shouldRenderDialog}>
        <FilePreviewDialog
          open={open}
          onClose={onClose}
          fileId={file.id}
          fileType={fileType}
          fileSrc={fileSrc}
          fileName={file.name}
          fileFormat={format}
          allowsDownload={allowsDownload}
          downloadSrc={allowsDownload ? href : undefined}
          getFileSrc={getFileSrcAsync}
        />
      </ShouldRender>
      <ShouldRender shouldRender={!withDialog}>
        <$Box>
          <FilePreviewContainer
            fileId={file.id}
            fileType={fileType}
            fileSrc={fileSrc}
            fileName={file.name}
            fileFormat={format}
            allowsDownload={allowsDownload}
            downloadSrc={allowsDownload ? href : undefined}
            getFileSrc={getFileSrcAsync}
          />
        </$Box>
      </ShouldRender>
    </ThemeProvider>
  );
};
