/* eslint-disable no-nested-ternary, multiline-ternary */
import { deepClone } from '@/Annotator/data/utils';
import { MonomerDefinition } from './NaturalMonomers';
import { MDLSDFReader } from 'webmolkit/io/MDLReader';
import { DataSheet, DataSheetColumn } from 'webmolkit/ds/DataSheet';
import { MolUtil } from 'webmolkit/mol/MolUtil';
import { Molecule } from 'webmolkit/mol/Molecule';
import { MDLMOLWriter } from 'webmolkit/io/MDLWriter';

export enum MonomerAssimilationType {
  Peptide = 'peptide',
  Nucleotide = 'nucleotide',
}

export class MonomerAssimilation {
  public customPeptides: MonomerDefinition[];
  public customNucleotides: MonomerDefinition[];

  constructor(customPeptides: MonomerDefinition[], customNucleotides: MonomerDefinition[]) {
    this.customPeptides = deepClone(customPeptides);
    this.customNucleotides = deepClone(customNucleotides);
  }

  public assimilate(srcData: string, typeHint: MonomerAssimilationType = null): void {
    let ds: DataSheet = null;
    try {
      ds = new MDLSDFReader(srcData).parse();
    } catch (err) {
      throw new Error('Monomers must be provided in the SDfile (.sdf) format.');
    }

    if (ds.numRows == 0) {
      throw new Error('Monomer file is empty.');
    }

    const colMol = ds.firstColOfType(DataSheetColumn.Molecule);
    if (colMol < 0) {
      throw new Error('Monomer file has no molecule column.');
    }

    let colCode = -1;
    for (let n = 0; n < ds.numCols; n++) {
      if (ds.colName(n).toLowerCase().startsWith('code')) {
        colCode = n;
        break;
      }
    }
    if (colCode < 0) {
      for (let n = 0; n < ds.numCols; n++) {
        if (ds.colName(n).toLowerCase().includes('code')) {
          colCode = n;
          break;
        }
      }
    }
    if (colCode < 0) {
      throw new Error('No column found for "Code".');
    }

    let colNatural = -1;
    for (let n = 0; n < ds.numCols; n++) {
      if (ds.colName(n).toLowerCase().startsWith('natural')) {
        colNatural = n;
        break;
      }
    }

    // TODO: make an actual guess
    if (typeHint == null) {
      typeHint = MonomerAssimilationType.Peptide;
    }
    const monomers = typeHint == MonomerAssimilationType.Peptide ? this.customPeptides
      : typeHint == MonomerAssimilationType.Nucleotide ? this.customNucleotides
        : null;

    let numImported = 0;
    const codesAlready = new Set<string>();

    for (let n = 0; n < ds.numRows; n++) {
      const mol = ds.getMolecule(n, colMol);
      if (MolUtil.isBlank(mol)) continue; // (maybe a warning?)

      const code = ds.getString(n, colCode);
      if (!code) continue; // (maybe a warning?)

      if (codesAlready.has(code)) continue;
      codesAlready.add(code);

      let natural = colNatural >= 0 ? ds.getString(n, colNatural) : null;
      if (!natural) {
        if (typeHint == MonomerAssimilationType.Peptide) {
          natural = 'X';
        } else {
          continue;
        }
      }

      if (this.assimilateMonomer(monomers, mol, code, natural)) {
        numImported++;
      }
    }

    if (numImported == 0) {
      throw new Error('No new valid monomers were imported.');
    }
  }

  private assimilateMonomer(monomers: MonomerDefinition[], mol: Molecule, code: string, natural: string): boolean {
    const molfile = new MDLMOLWriter(mol).writeEither();

    for (const look of monomers) {
      if (look.code == code) {
        if (look.molfile == molfile) return false;
        look.name = code;
        look.molfile = molfile;
        look.natural = natural;
        return true;
      }
    }

    monomers.push({
      name: code,
      code,
      natural,
      molfile,
    });
    return true;
  }
}
