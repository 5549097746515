import { molecule_properties } from '../Properties/calculator';
import { Collection, filterByCollections, getCollectionByName, getPatentInformation } from './Collections';
import { DLSimilarityResponse, DLError, ReferenceStructure, SimilarStructure, SimilaritySettings } from './SimilarityResultsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processSimilarityResults(json: any): DLSimilarityResponse | DLError {
  if (json.result?.hits) {
    json.result.hits.forEach(hit => {
      hit.identifiers.forEach(identifier => {
        identifier.collection = getCollectionByName(identifier.collection);
      });
    });
  }
  return json;
}

const mapByCollections = (
  similarStructure: SimilarStructure,
): Map<Collection, string> => {
  const map = new Map();
  similarStructure.identifiers.forEach((identifier) => {
    map.set(identifier.collection, identifier.name);
  });
  return map;
};

export const similarityToCSV = (props: {
  reference: ReferenceStructure,
  similarStructures: Array<SimilarStructure>,
  displayProperties: string[],
  similaritySettings: SimilaritySettings,
}) => {
  const { reference, similarStructures, displayProperties, similaritySettings } = props;
  const collections = [...similaritySettings.filters].sort();
  const addPatents = collections.includes(Collection.SureChEMBL) && similaritySettings.showCollectionInformation;

  const exportProperties = molecule_properties.filter(p => displayProperties.includes(p.key));
  const header: string[] = [...collections];
  if (addPatents) {
    header.push('first patent', 'patent date');
  }
  header.push('smiles', 'scaffold', 'similarity', 'tanimoto', 'selected');

  const csvData = [[...header, ...exportProperties.map(p => p.short)]];

  const row = [];
  collections.forEach(() => row.push(reference.name));
  if (addPatents) {
    row.push('', '');
  }
  row.push(reference.structure, reference.scaffold, 1.0.toFixed(3), 1.0.toFixed(3), '');
  exportProperties.forEach((property) => {
    const value = reference.properties[property.key];
    row.push(!value ? '' : value.toFixed(property.precision));
  });
  csvData.push(row);

  let filteredStructures = filterByCollections(similarStructures, similaritySettings.filters);
  filteredStructures = filteredStructures.sort((a, b) => b.similarity - a.similarity);
  if (similaritySettings.grouped) {
    const scaffolds = [];
    filteredStructures.forEach((structure) => {
      if (!scaffolds.includes(structure.scaffold)) {
        scaffolds.push(structure.scaffold);
      }
    });
    const groupedStructures = [];
    scaffolds.forEach((scaffold) => {
      const group = filteredStructures.filter((structure) => structure.scaffold === scaffold);
      groupedStructures.push(group);
    });
    filteredStructures = groupedStructures.flat();
  }
  filteredStructures.forEach((structure) => {
    const byCollection = mapByCollections(structure);
    const names = collections.map((collection) => byCollection.get(collection) || '');
    const row = [
      ...names,
    ];
    if (addPatents) {
      const patentInformation = getPatentInformation(structure.identifiers);
      row.push(patentInformation?.first_patent || '', patentInformation?.first_date || '');
    }
    row.push(
      structure.structure,
      structure.scaffold,
      structure.similarity.toFixed(3),
      structure.tanimoto.toFixed(3),
      structure.selected ? 'selected' : '',
    );
    exportProperties.forEach((property) => {
      const value = structure.properties[property.key];
      row.push(!value ? '' : value.toFixed(property.precision));
    });
    csvData.push(row);
  });

  return csvData.map((row) => row.join(',')).join('\n');
};

export const similarityToBlob = (props: {
  reference: ReferenceStructure,
  similarStructures: Array<SimilarStructure>,
  displayProperties: string[],
  similaritySettings: SimilaritySettings,
}) => {
  return new Blob([similarityToCSV(props)], {
    type: 'text/csv;charset=utf-8;',
  });
};

export const downloadSearch = (props: {
  reference: ReferenceStructure,
  similarStructures: Array<SimilarStructure>,
  displayProperties: string[],
  similaritySettings: SimilaritySettings,
}) => {
  const blob = similarityToBlob(props);
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', 'search_result.csv');
  link.click();
};
