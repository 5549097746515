import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { getRootStore } from '@/stores/rootStore';
import { FormDefinition } from '@/Annotator/data/forms';
import { FieldDefinition } from '@/FieldDefinitions/types';
import { OntologyTemplate } from '@/Annotator/data/templates';
import { EditFormDialog } from '@/FormDefinitions/components/editor/EditFormDialog';
import { ColumnDef, SimpleDataTable, AddButton } from '@/shared/components';
import { CatchUnhandledErrors } from '@/ErrorReporting/CatchUnhandledErrors';
import { term } from '@/shared/utils/stringUtils';

type Props = {
  vault_id: number;
  ontology_templates: Array<OntologyTemplate>;
  protocol_field_definitions: Array<FieldDefinition>;
  svg_icons_for_annotator: Array<string>;
  png_icons_for_annotator: Array<string>;
};

const columns: Array<ColumnDef & { id: keyof FormDefinition }> = [
  {
    id: 'name',
    label: 'Name',
    type: 'link',
  },
  {
    id: 'created_at',
    label: 'Created date',
    type: 'date',
  },
  {
    id: 'updated_at',
    label: 'Last updated',
    type: 'date',
  },
];

@observer
export default class FormDefinitionsPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    const { formDefinitionsStore } = getRootStore();
    formDefinitionsStore.setVaultId(this.props.vault_id);
    formDefinitionsStore.editFormDefinitionStore.setup(this.props);
  }

  renderTopRow() {
    const {
      formDefinitionsStore: {
        editFormDefinitionStore: {
          handleCreateForm,
        },
      },
    } = getRootStore();

    const strProtocolTitle = term('protocol', true);

    return (
      <div className='top-row'>
        <Grid container alignItems='center'>
          <Grid item xs={5}>
            <Box display='flex' justifyContent='left' alignItems='center'>
              <h2>{strProtocolTitle} Forms</h2>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box display='grid' justifyContent='end'>
              <AddButton
                onClick={handleCreateForm}
                text={'Create a new form'}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const {
      formDefinitionsStore: {
        sortBy,
        setSortBy,
        displayRows,
        editFormDefinitionStore,
        editFormDefinitionStore: {
          handleEditForm,
        },
      },
    } = getRootStore();

    return (
      <>
        <div className="subcontainer">
          {this.renderTopRow()}
          <SimpleDataTable
            stickyHeaders
            columns={columns}
            rows={displayRows}
            onClickRow={handleEditForm}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />

          <EditFormDialog
            store={editFormDefinitionStore}
            ontology_templates={this.props.ontology_templates}
            protocol_field_definitions={this.props.protocol_field_definitions}
          />
        </div>

        <CatchUnhandledErrors />
      </>
    );
  }
}
