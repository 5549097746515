import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { OntologyTemplate, TemplateAssignment, SuggestionType } from '../data/templates';
import './Template.sass';
import { Annotation } from '../data/annotations';
import { expandPrefix, expandPrefixes } from '../data/utils';
import { StandardDialogActions } from '@/shared/components';

type Props = {
  isOpen: boolean,
  template: OntologyTemplate,
  assn: TemplateAssignment,
  groupNest: string[],
  initValue: string,
  onDialogCancel: () => void,
  onDialogSubmit: (assn: TemplateAssignment, annotation: Annotation) => void,
};

type State = {
  currentValue: string,
};

export class LabelDialog extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: props.initValue,
    };
  }

  get dialogTitle() {
    if (!this.props.assn) return '';

    const TYPENAMES = {
      [SuggestionType.Field]: 'Field',
      [SuggestionType.URL]: 'URL',
      [SuggestionType.ID]: 'Identifier',
      [SuggestionType.String]: 'Text',
      [SuggestionType.Number]: 'Number',
      [SuggestionType.Integer]: 'Integer',
      [SuggestionType.Date]: 'Date',
    };
    const typeName = TYPENAMES[this.props.assn.suggestions] ?? 'Add Free Text';
    return `${typeName}: ${this.props.assn.name}`;
  }

  public render() {
    return (
      <Dialog
        className='EditTeamDialog edit-account-object-dialog'
        disablePortal
        onClose={this.props.onDialogCancel}
        open={this.props.isOpen}
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderDialog()}
      </Dialog>
    );
  }

  private renderDialog(): JSX.Element {
    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding" style={{ overflowX: 'hidden' }}>
          {this.renderContent()}
        </DialogContent>

        <StandardDialogActions
          className="project__action"
          cancelProps={{
            onClick: this.props.onDialogCancel,
          }}
          defaultButtonProps={{
            onClick: this.handleSubmit,
            label: 'Save',
          }}
        />
      </>
    );
  }

  private renderContent(): JSX.Element {
    if (!this.props.assn) return null;

    let suggestType = this.props.assn.suggestions;
    if (suggestType == SuggestionType.Full || suggestType == SuggestionType.Disabled) suggestType = SuggestionType.String;

    const inputClass = ['input-text', 'OntoLabelEdit-' + suggestType];
    let placeholder = '';

    if (suggestType == SuggestionType.Date) {
      placeholder = 'YYYY-MM-DD';
    }

    return (
      <>
        <input
          className={inputClass.join(' ')}
          type="text"
          defaultValue={this.props.initValue}
          placeholder={placeholder}
          onKeyUp={(event) => this.changedValue(event.key, event.target as HTMLInputElement)}
          autoFocus={true}
        />
      </>
    );
    // TODO: add a line for "why its bad" validation message (fixed height)
  }

  private changedValue(key: string, input: HTMLInputElement): void {
    this.setState({ currentValue: input.value });

    // TODO: validation and feedback
  }

  private handleSubmit = (): void => {
    const value = this.state.currentValue;
    if (!value) return;

    // TODO: some validation

    const { assn, groupNest } = this.props;
    this.props.onDialogSubmit(assn, {
      propURI: expandPrefix(assn.propURI),
      groupNest: expandPrefixes(groupNest),
      valueLabel: value,
    });
  };
}
