/* eslint-disable multiline-ternary, no-nested-ternary */

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { OntologyTemplate, TemplateGroup } from '../data/templates';
import './Template.sass';
import { TemplateServices } from '../data/TemplateServices';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { StandardDialogActions } from '@/shared/components';

type Props = {
  isOpen: boolean,
  svc: TemplateServices,
  templateList: OntologyTemplate[],
  bootTemplate: OntologyTemplate,
  onDialogCancel: () => void,
  onDialogSubmit: (template: OntologyTemplate) => void,
};

type State = {
  watermarkUpdate: number,
};

export class TemplateNewDialog extends React.Component<Props, State> {
  private template: OntologyTemplate;
  private lastOpen = false;

  constructor(props) {
    super(props);
    this.state = { watermarkUpdate: 0 };
    this.template = this.emptyTemplate();
  }

  private emptyTemplate(): OntologyTemplate {
    return { schemaPrefix: 'http://', root: { name: '', groupURI: null, assignments: [], subGroups: [] } };
  }

  get dialogTitle() {
    return 'New Template';
  }

  public render() {
    const { isOpen } = this.props;
    if (isOpen != this.lastOpen) {
      this.template = this.props.bootTemplate || this.emptyTemplate();
      this.lastOpen = isOpen;
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onDialogCancel}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderDialog()}
      </Dialog>
    );
  }

  private renderDialog(): JSX.Element {
    return (
      <>
        <DialogTitle className='muiDialog-title'>
          {this.dialogTitle}
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          {this.renderContent()}
        </DialogContent>

        <StandardDialogActions className="project__action"
          cancelProps={{
            onClick: this.props.onDialogCancel,
          }}
          defaultButtonProps={{
            onClick: this.handleSubmit,
            label: 'Save',
          }}
        />
      </>
    );
  }

  private renderContent(): JSX.Element {
    const inputClassName = ['input-text', 'OntoLabelEdit-string', 'ProtocolAnnotator-wideinput-gift'];
    const inputClassPrefix = [...inputClassName];
    if (!this.validateName(this.template.root.name)) inputClassName.push('OntoLabelEdit-invalid');
    if (!this.validatePrefix(this.template.schemaPrefix)) inputClassPrefix.push('OntoLabelEdit-invalid');

    let numAssn = 0;
    const countGroup = (group: TemplateGroup) => {
      numAssn += group.assignments ? group.assignments.length : 0;
      for (const subGroup of (group.subGroups || [])) {
        countGroup(subGroup);
      }
    };
    countGroup(this.template.root);

    const inlineExisting = numAssn > 0 ? (
      <>
        <div style={{ margin: '0.5em 0 1em 0' }}>
          Importing an existing template, which contains {numAssn} existing assignment{numAssn == 1 ? '' : 's'}.
        </div>
      </>
    ) : null;

    return (
      <>
        <div className="OntologyTemplate-schemagrid-entries">
          <div style={{ gridRow: 1, gridColumn: 'title' }}>
            <b>Name:</b>
          </div>
          <div style={{ gridRow: 1, gridColumn: 'entry', width: '100%' }}>
            <div style={{ display: 'inline-flex', width: '100%' }}>
              <input
                className={inputClassName.join(' ')}
                type="text"
                value={this.template.root.name}
                onChange={(event) => this.changedName(event.target as HTMLInputElement)}
                autoFocus={true}
                name="name"
              />
            </div>
          </div>
          <div style={{ gridRow: 2, gridColumn: 'entry', margin: '0.5em 0 1em 0' }}>
            Choose a concise name, ideally 15-25 characters long, using lower case (except for proper nouns).
          </div>

          <div style={{ gridRow: 3, gridColumn: 'title' }}>
            <b>Schema Prefix:</b>
          </div>
          <div style={{ gridRow: 3, gridColumn: 'entry', width: '100%' }}>
            <div style={{ display: 'inline-flex', width: '100%' }}>
              <input
                className={inputClassPrefix.join(' ')}
                type="text"
                value={this.template.schemaPrefix}
                onChange={(event) => this.changedPrefix(event.target as HTMLInputElement)}
                name="schemaPrefix"
              />
            </div>
          </div>
          <div style={{ gridRow: 4, gridColumn: 'entry', margin: '0.5em 0 1em 0' }}>
            Provide a URI base that is globally unique, and is suitable for using as a prefix for custom terms. The
            recommended format is <span className="OntologyTemplate-code">http://domain.name/base#</span>.
            The schema prefix cannot be changed later, so choose wisely.
          </div>
        </div>

        {inlineExisting}

        <div>
          <u>Current Templates</u>
        </div>

        <div className="OntologyTemplate-schemagrid-shorter">
          {
            this.props.templateList.map((template, idx) => (
              <React.Fragment key={`template-${idx}`}>
                <div style={{ gridRow: idx + 1, gridColumn: 'title' }}>
                  <b>{template.root.name}</b>
                </div>
                <div style={{ gridRow: idx + 1, gridColumn: 'uri' }}>
                  {template.schemaPrefix}
                </div>
              </React.Fragment>
            ))
          }
        </div>
      </>
    );
  }

  private validateName(name: string): boolean {
    return name.trim().length >= 3;
  }

  private validatePrefix(pfx: string): boolean {
    return /^https?:\/\/[a-z.]+\/\w+/.test(pfx);
  }

  private changedPrefix(input: HTMLInputElement): void {
    this.template.schemaPrefix = input.value;
    this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
  }

  private changedName(input: HTMLInputElement): void {
    this.template.root.name = input.value;
    this.setState({ watermarkUpdate: this.state.watermarkUpdate + 1 });
  }

  private handleSubmit = (): void => {
    const { template } = this;

    if (!template.root.name) {
      ModalUtils.showModal('Must provide template name.', {});
      return;
    }

    (async () => {
      const check = await this.props.svc.checkTemplateDuplicate(template.schemaPrefix);
      if (check.valid) {
        this.props.onDialogSubmit(template);
      } else {
        ModalUtils.showModal(check.reason, {});
      }
    })();
  };
}
