/* eslint-disable @typescript-eslint/no-unused-vars, no-jquery/no-jquery-constructor, no-jquery/no-each-collection, no-jquery/no-find-collection, no-jquery/no-other-methods, no-jquery/no-find-collection, @typescript-eslint/no-non-null-assertion */

import React from 'react';
import { EditFormDefinitionStore } from '@/FormDefinitions/stores/editFormDefinitionStore';
import { FormRow } from './Row';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';

const DRAG_ANIMATION_DURATION = 100;

type Props = {
  store: EditFormDefinitionStore;
}

type State = {
  isDragging: boolean;
  isAnimating: boolean;
  snapshot: { __html: string } | null;
}

const renderCount = 1;
let layoutChange = 1;

@observer
export class FormContents extends React.Component<Props, State> {
  refRenderedRows = React.createRef<HTMLDivElement>();
  refDragDisplay = React.createRef<HTMLDivElement>();

  disposers: Array<() => void> = [];
  lastRenderedRowsJSON = '';
  transitions: Record<string, { from: DOMRect, to: DOMRect, endTime: number, startTime: number }> = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      isDragging: false,
      isAnimating: false,
      snapshot: null,
    };
  }

  getFieldBoundingRect = (el: HTMLElement, containerBounds: DOMRect) => {
    const r = el.getBoundingClientRect();

    const x = r.left - containerBounds.left;
    const y = r.top - containerBounds.top;
    const w = r.width - 2;
    const h = r.height - 2;

    return new DOMRect(x, y, w, h);
  };

  getDraggableHtml = (el: HTMLElement, contentsBounds?: DOMRect) => {
    const key = el.dataset.draggable;

    if (!contentsBounds) {
      contentsBounds = this.refRenderedRows.current.getBoundingClientRect();
    }
    const { x, y, width, height } = this.getFieldBoundingRect(el, contentsBounds);

    const additionalClass =
      this.props.store.dragStore.dragState.currentDraggableId === key ? 'FormField--dragging' : '';
    return el.outerHTML.replace(
      'class="',
      `style="position: absolute; top: ${y}px; left: ${x}px; width: ${width}px; height: ${height}px;" class="${additionalClass} `);
  };

  componentDidMount() {
    const { store } = this.props;
    this.disposers.push(
      reaction(
        () => store.dragStore.dragState.isDragging,
        isDragging => {
          if (isDragging && this.refRenderedRows.current) {
            const contentsBounds = this.refRenderedRows.current.getBoundingClientRect();
            let dragOverlayHTML = '';
            const $src = $(this.refRenderedRows.current);
            $src.find('.FormField').each((i, el) => {
              dragOverlayHTML += this.getDraggableHtml(el, contentsBounds);
            });

            this.setState({
              isDragging: true,
              snapshot: { __html: dragOverlayHTML },
            });
            this.lastRenderedRowsJSON = JSON.stringify(store.displayedRows);
            this.handleDragAnimation();
          } else {
            this.setState({
              isDragging: false,
            });
          }
        },
      ),
    );
  }

  componentWillUnmount() {
    this.disposers.forEach(d => d());
  }

  handleDragAnimation = () => {
    if (Object.keys(this.transitions).length) {
      this.setState({ isAnimating: true });

      const now = Date.now();
      Object.keys(this.transitions).forEach(key => {
        const transition = this.transitions[key];
        // document.querySelectorAll
        const el = $(this.refDragDisplay.current).find(`[data-draggable="${key}"]`);
        const duration = transition.endTime - transition.startTime;
        const f1 = Math.min((now - transition.startTime) / duration, 1);
        const f2 = 1 - f1;

        const translateX = transition.from.left * f2 + transition.to.left * f1;// - parseFloat(el.css('left'));
        const translateY = transition.from.top * f2 + transition.to.top * f1;// - parseFloat(el.css('top'));
        el.css({
          transform: `translate(${translateX}px, ${translateY}px)`,
          width: (transition.from.width * f2 + transition.to.width * f1) + 'px',
          height: (transition.from.height * f2 + transition.to.height * f1) + 'px',
        });
        if (f1 >= 1) {
          delete this.transitions[key];
        }
      });
    }

    if (Object.keys(this.transitions).length) {
      requestAnimationFrame(this.handleDragAnimation);
    } else {
      this.setState({ isAnimating: false });
    }
  };

  handleLayoutChange = () => {
    // handle layout changes by animating the form fields in drag-overlay
    ++layoutChange;

    if (this.refDragDisplay.current && this.refRenderedRows.current) {
      // if the rendered rows contain a form field that is not in the current overlay (that is, forms that are being
      // dragged into the form), then we need to add it to the overlay.
      this.refRenderedRows.current.querySelectorAll<HTMLInputElement>('.FormField').forEach(el => {
        const key = el.dataset.draggable;
        if (this.refDragDisplay.current.querySelectorAll<HTMLInputElement>(`[data-draggable="${key}"]`).length === 0) {
          $(this.refDragDisplay.current).append(this.getDraggableHtml(el));
        }
      });

      // but if the overlay contains a form field that is not in the rendered rows, we need to remove it from the overlay
      this.refDragDisplay.current.querySelectorAll<HTMLInputElement>('.FormField').forEach(el => {
        const key = el.dataset.draggable;
        if (this.refRenderedRows.current.querySelectorAll<HTMLInputElement>(`[data-draggable="${key}"]`).length === 0) {
          el.remove();
        }
      });

      // helper functions to iterate over all rendered form fields and obtain bounds
      const obtainFormFieldBounds = (parentElement: HTMLElement) => {
        const result: { [key: string]: DOMRect } = {};
        const containerBounds = parentElement.getBoundingClientRect();
        $(parentElement).find('.FormField').toArray().forEach((el, i) => {
          const id = el.dataset.draggable;
          let { x, y, width, height } = this.getFieldBoundingRect(el, containerBounds);

          if (parentElement === this.refDragDisplay.current) {
            x = parseFloat(el.style.left!);
            y = parseFloat(el.style.top!);
          }
          result[id] = new DOMRect(x, y, width, height);
        });
        return result;
      };

      const renderedPositions = obtainFormFieldBounds(this.refRenderedRows.current!);
      const currentPositions = obtainFormFieldBounds(this.refDragDisplay.current!);

      Object.keys(renderedPositions).forEach(id => {
        const el = this.refDragDisplay.current.querySelectorAll<HTMLElement>(`[data-draggable="${id}"]`);

        if (el[0]) {
          const startTime = Date.now();
          const endTime = startTime + DRAG_ANIMATION_DURATION;
          const rectFrom = currentPositions[id];
          const rectTo = renderedPositions[id];

          let fromX = 0, fromY = 0;
          if (el[0].style.transform) {
            const [translateX, translateY] = el[0].style.transform.replace('translate(', '').replace(')', '').split(',').map(v => parseFloat(v));
            fromX = translateX;
            fromY = translateY;
          }

          const fromWidth = Math.abs(rectFrom.width - rectTo.width) < 10 ? rectTo.width : rectFrom.width;

          this.transitions[id] = {
            from: new DOMRect(fromX, fromY, fromWidth, rectTo.height),
            to: new DOMRect(rectTo.left - rectFrom.left, rectTo.top - rectFrom.top, rectTo.width, rectTo.height),
            startTime,
            endTime,
          };
        }
      });
      this.handleDragAnimation();
    }
  };

  render() {
    const { store: { displayedRows } } = this.props;

    const displayedRowsJSON = JSON.stringify(displayedRows);

    if (this.lastRenderedRowsJSON !== displayedRowsJSON) {
      this.lastRenderedRowsJSON = displayedRowsJSON;
      setTimeout(this.handleLayoutChange);
    }

    const renderDragOverlay = this.state.isDragging || this.state.isAnimating;

    return <>
      <div className='FormContents'>
        {renderDragOverlay && <div ref={this.refDragDisplay} className='drag-overlay' dangerouslySetInnerHTML={this.state.snapshot} />}

        <div ref={this.refRenderedRows} className={renderDragOverlay ? 'render-drag-true' : 'render-drag-false'}>
          {
            displayedRows.map((row, index) => (<FormRow key={row.layoutID} row={row} {...this.props} />))
          }
        </div>
      </div>
    </>;
  }
}
