import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { LocationAncestryResponseProps } from '@/Inventory/components/types';
import { ShowLocationTreeView } from '@/Samples/components/location_editor/ShowLocationTreeView';
import { LocationNode } from '@/Samples/types';
import { ExpandMoreButton } from '@/shared/components/ExpandMoreButton';
import { Popover } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FilterValueSelectProps } from './FilterValueSelect.types';

type Props = FilterValueSelectProps<FieldDataType.Location>;
@observer
export class FilterLocationValue extends React.Component<Props> {
  private anchorEl: HTMLElement = null;

  get value() {
    const {
      filter: { field_values },
    } = this.props;
    return (field_values && field_values[0]) || '';
  }

  constructor(props: Props) {
    super(props);
    makeObservable(this, {
      locations: observable,
      selectOpen: observable,

      currentLocationNode: computed,
      currentLocation: computed,
      flatLocationNodes: computed,

      setOpen: action,
      handleChange: action,
      setLocations: action,
      closeSelect: action,
      openSelect: action,
    });
    if (!props.filter.field_values[0]) {
      return;
    }
    const locationId = props.filter.field_values[0];
    this.getAncestryForCurrentLocation(Number.parseInt(locationId as string));
  }

  locations: LocationNode[] = [];
  ancestryHash: Record<number, string> = {};
  selectOpen = false;

  componentDidMount = async () => {
    if (this.locations.length === 0) {
      this.setLocations();
    }
  };

  setLocations = async () => {
    const { id: fdId } = this.props.fieldDefinition;
    const locations = await this.props.getValues({ fd_id: fdId as number });
    this.locations = locations as LocationNode[];
  };

  handleChange = async (locationId: number) => {
    if (!this.ancestryHash[locationId]) {
      await this.getAncestryForCurrentLocation(locationId);
    }
    this.closeSelect();
    this.props.handleValueChange([locationId as any]);
  };

  setOpen = (open: boolean) => {
    this.selectOpen = !!open;
  };

  get flatLocationNodes() {
    let nodesToCheck = this.locations;
    let flatNodes = [];
    while (nodesToCheck.length > 0) {
      const node = nodesToCheck[nodesToCheck.length - 1];
      flatNodes = [...flatNodes, node];
      nodesToCheck = [...nodesToCheck.slice(0, -1), ...(node.children || [])];
    }
    return flatNodes;
  }

  get currentLocationNode() {
    if (this.props.filter.field_values.length == 0) {
      return undefined;
    }
    return this.flatLocationNodes.find(
      (val) => (this.props.filter.field_values[0] as any as number) == val.id,
    );
  }

  async getAncestryForCurrentLocation(locationId: number) {
    const { id: fd_id } = this.props.fieldDefinition;
    const ancestryHash = (await this.props.getValues({
      fd_id: fd_id as number,
      location_id: locationId,
    })) as LocationAncestryResponseProps;
    this.ancestryHash = { ...this.ancestryHash, ...ancestryHash };
  }

  get currentLocation() {
    const { currentLocationNode, ancestryHash } = this;
    if (!currentLocationNode) {
      return null;
    }
    return ancestryHash[currentLocationNode.id];
  }

  openSelect = () => {
    this.setOpen(true);
  };

  closeSelect = () => {
    this.setOpen(false);
  };

  toggleSelect = () => {
    this.setOpen(!this.selectOpen);
  };

  render() {
    const { locations } = this;
    if (locations.length === 0) {
      return <></>;
    }
    return (
      <>
        <span
          ref={(el) => (this.anchorEl = el)}
          onClick={(e) => {
            if (!(e.target instanceof SVGElement)) {
              this.toggleSelect();
            }
          }}
          className={`filter_location_field MuiInputBase-colorPrimary MuiOutlinedInput-notchedOutline${this.selectOpen ? ' filter_location_field__active' : ''
            }`}
        >
          {this.currentLocation || 'Select A Location'}
          <ExpandMoreButton
            expanded={this.selectOpen}
            onClick={this.toggleSelect}
          />
        </span>
        <Popover
          open={this.selectOpen}
          anchorEl={this.anchorEl}
          onClose={this.closeSelect}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="filter_location_field__dropdown">
            <ShowLocationTreeView
              selectedTreeNode={this.currentLocationNode}
              nodes={locations}
              onSetSelectedNode={this.handleChange}
              hidden={!this.selectOpen}
            />
          </div>
        </Popover>
      </>
    );
  }
}
