import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import MuiTheme from '@/shared/components/MuiTheme';
import { AnyObject } from '@/types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { makeObservable, observable } from 'mobx';
import React from 'react';

export type InventoryExportOptions = { include_events: boolean };
type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (props: InventoryExportOptions) => Promise<void>;
};
type FormState = {
  errors: AnyObject;
  data: InventoryExportOptions;
};
const { column, checkbox } = layoutBuilder;

export class InventoryExportDialog extends React.Component<Props> {
  formState: FormState = {
    data: {
      include_events: false,
    },
    errors: {},
  };

  constructor(props: Props) {
    super(props);
    makeObservable(this, {
      formState: observable,
    });
  }

  get layout() {
    return column({ spacing: 2 }, [
      checkbox({
        key: 'include_events',
        label: 'Include Event Rows',
      }),
    ]);
  }

  async onSave(): Promise<void> {
    await this.props.onSave(this.formState.data);
    this.props.onClose();
  }

  get renderValueIfPresent() {
    return (
      <>
        <DialogTitle className="muiDialog-title">Export Options</DialogTitle>
        <DialogContent>
          <CDDModalForm
            data={this.formState.data}
            onCancel={this.props.onClose}
            onOK={() => this.onSave()}
            terminology={{ OK: 'Submit' }}
            layout={this.layout}
            formState={this.formState}
          />
        </DialogContent>
      </>
    );
  }

  render() {
    return (
      <MuiTheme>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          className="EditInventoryDialog"
          maxWidth={false}
          fullWidth={false}
          PaperProps={{
            style: {
              minWidth: '300px', // Set a minimum width if needed
              maxWidth: 'none', // Ensure dialog is not restricted by width
            },
          }}
        >
          {this.renderValueIfPresent}
        </Dialog>
      </MuiTheme>
    );
  }
}
