import React, { useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  Link,
  Divider,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { molecule_properties } from './calculator';
import { noSort, PropertySort, toggleSort } from './propertySorting';
import sortOffIcon from 'ASSETS/images/cdd30/layout/sort/off.svg';
import sortUpIcon from 'ASSETS/images/cdd30/layout/sort/up.svg';
import sortDownIcon from 'ASSETS/images/cdd30/layout/sort/down.svg';
import { Img } from '@/shared/components/sanitizedTags.js';

const PropertySelection = (props: {
  selected: string[];
  onChange: (selection: string[], sortBy: PropertySort) => void;
  sortBy?: PropertySort;
  supportSort?: boolean;
}) => {
  const { selected, onChange, supportSort = false, sortBy } = props;

  const selectAll = () => {
    onChange(molecule_properties.map((property) => property.key), sortBy);
  };

  const selectNone = () => {
    onChange([], noSort);
  };

  const handleSortChange = (newSortBy: PropertySort) => {
    const newSelected = [...selected];
    if (!newSelected.includes(newSortBy.sortKey) && newSortBy.sortKey !== '') {
      newSelected.push(newSortBy.sortKey);
    }
    onChange(newSelected, newSortBy);
  };

  const handleSelectionChange = (property: string) => {
    const newSortBy = supportSort && sortBy.sortKey === property ? noSort : sortBy;
    const newSelected = selected.includes(property)
      ? selected.filter((item) => item !== property)
      : [...selected, property];
    onChange(newSelected, newSortBy);
  };

  const content = (
    <FormGroup>
      {molecule_properties.map((property, index) => {
        const chkbox = <Checkbox
          sx={{ pt: '0.1em', pb: '0.1em' }}
          checked={selected.includes(property.key)}
          onChange={() => { handleSelectionChange(property.key); }
          } />;
        let label: string | JSX.Element = property.label;
        if (supportSort) {
          label = <SortSelectorLabel
            sortKey={property.key}
            sortBy={sortBy}
            onClick={() => handleSelectionChange(property.key)}
            onSortChange={handleSortChange}
            label={label} />;
        }
        return <FormControlLabel key={index} control={chkbox} label={label} />;
      })}
    </FormGroup>
  );

  return (
    <Stack direction='column' data-testid='property-selection-list'>
      <Stack direction='row' justifyContent='space-between'>
        <Box>Select properties</Box>
        <Box><Link onClick={selectAll}>All</Link> / <Link onClick={selectNone}>None</Link></Box>
      </Stack>
      <Divider orientation="horizontal" sx={{ pt: 0, pb: 0 }} />
      {content}
    </Stack>
  );
};

const SortSelectorLabel = (props: {
  sortKey: string,
  sortBy: PropertySort,
  onClick: (event: React.MouseEvent) => void,
  onSortChange: (newSort: PropertySort) => void,
  label: string | JSX.Element;
}) => {
  const { label, sortKey, sortBy, onClick, onSortChange } = props;
  const [showArrow, setShowArrow] = useState(false);
  const handleSortClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onSortChange(toggleSort(sortBy, sortKey));
  };
  const handleLabelClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onClick(event);
  };
  const icon = {
    asc: <Img alt='Ascending' src={sortUpIcon} data-testid='sort-ascending' />,
    desc: <Img alt='Descending' src={sortDownIcon} data-testid='sort-descending' />,
    none: <Img alt='No sort' src={sortOffIcon} data-testid='no-sort' />,
  }[sortBy.sortKey === sortKey ? sortBy.order : 'none'];

  return <Stack direction='row'
    onMouseEnter={() => setShowArrow(true)}
    onMouseLeave={() => setShowArrow(false)}
  >
    <Box onClick={handleLabelClick} data-testid='sort-property-selector'>{label}</Box>
    <Box onClick={handleSortClick} width='1.5em' display='flex' alignItems='center' justifyContent='center'>{icon}</Box>
  </Stack>;
};

export {
  PropertySelection,
};
