/* eslint-disable no-nested-ternary, multiline-ternary */
import React from 'react';
import { MonomerDefinition } from './NaturalMonomers';
import { A } from '@/shared/components/sanitizedTags.js';
import { CustomMonomerDialog } from './CustomMonomerDialog';
import { MonomerAssimilation, MonomerAssimilationType } from './MonomerAssimilation';
import { CDD } from '@/typedJS';
import { ModalUtils } from '@/shared/utils/modalUtils';

type Props = {
  customPeptides: MonomerDefinition[],
  customNucleotides: MonomerDefinition[],
  handleSetMonomers: (customPeptides: MonomerDefinition[], customNucleotides: MonomerDefinition[]) => void;
  typeHint: MonomerAssimilationType;
}

type State = {
  draggingActive: boolean;
  isEditing: boolean;
};

export class CustomMonomerView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      draggingActive: false,
      isEditing: false,
    };

    // special deal: integration tests can't do drag'n'drop, so we need another way
    if (CDD.testEnvironment) {
      (window as any).TEST_customMonomerImportMonomers = (txt: string) => this.grabSourceContent(txt); // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  }

  public render(): JSX.Element {
    const { customPeptides, customNucleotides } = this.props;

    const classList = ['composeMacromol-custommonomers'];
    if (this.state.draggingActive) classList.push('composeMacromol-custommonomers-dragging');

    const notePeptides = `${customPeptides.length} custom peptide${customPeptides.length == 1 ? '' : 's'}`;
    const noteNucleotides = `${customNucleotides.length} custom nucleotide${customNucleotides.length == 1 ? '' : 's'}`;

    return (<>
      <div
        className={classList.join(' ')}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        >
        <A href="#" onClick={this.handleEdit}>
          {notePeptides}
          ,{' '}<br/>
          {noteNucleotides}
        </A>
      </div>
      <CustomMonomerDialog
        isOpen={this.state.isEditing}
        customPeptides={customPeptides}
        customNucleotides={customNucleotides}
        handleClose={this.handleCloseEdit}
        />
    </>);
  }

  private grabSourceContent(txt: string): void {
    const { customPeptides, customNucleotides } = this.props;
    const assim = new MonomerAssimilation(customPeptides, customNucleotides);
    try {
      assim.assimilate(txt, this.props.typeHint);
    } catch (ex) {
      ModalUtils.showModal(ex.toString());
      return;
    }
    this.props.handleSetMonomers(assim.customPeptides, assim.customNucleotides);
  }

  private handleEdit = (): void => {
    this.setState({ isEditing: true });
  };

  private handleDragLeave = (): void => {
    this.setState({ draggingActive: false });
  };

  private handleDragOver = (event: React.DragEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ draggingActive: true });
  };

  private handleDrop = (event: React.DragEvent): void => {
    event.preventDefault();
    this.setState({ draggingActive: false });

    const { dataTransfer } = event;
    const txt = dataTransfer.getData('text');
    if (txt) {
      this.grabSourceContent(txt);
      return;
    }

    if (dataTransfer.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const txt = event.target.result.toString();
        this.grabSourceContent(txt);
      };
      reader.readAsText(dataTransfer.files[0]);
    }
  };

  private handleCloseEdit = (): void => {
    this.setState({ isEditing: false });
  };
}
