import { observer } from 'mobx-react';
import React from 'react';

import { LocationNode } from '@/Samples/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SortableTreeView } from '@/shared/components/SortableTreeView/SortableTreeView';
import { LocationUtils } from '../location/locationUtils';

import { StringOrNumber } from '@/types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GridOnIcon from '@mui/icons-material/GridOn';

type Props = Omit<
  React.ComponentProps<typeof SortableTreeView>,
  'renderItemContents' | 'onChangeOrder'
> & {
  nodes: Array<LocationNode>;
  selectedTreeNode: LocationNode;
  onSetSelectedNode(nodeId: StringOrNumber): void;
};

@observer
export class ShowLocationTreeView extends React.Component<Props> {
  renderItemContents = (node: LocationNode) => {
    const childCounts = LocationUtils.countChildren(node);
    const label = node.value;
    const labelAttributes = {};

    let icon;
    if (LocationUtils.isNodeBox(node)) {
      icon = node.organized ? <GridOnIcon /> : <CheckBoxOutlineBlankIcon />;
    }

    return (
      <div
        className="tree-item-contents"
        data-treenodeid={'' + node.id}
        onClick={() => {
          this.props.onSetSelectedNode(node.id);
        }}
      >
        <div
          className="label"
          onClick={() => {
            this.props.onSetSelectedNode(node.id);
          }}
        >
          <span
            className={`location-box-icon ${childCounts.used ? 'filled-box' : ''
              }`}
          >
            {icon}
          </span>
          {label}
        </div>
      </div>
    );
  };

  render() {
    const { selectedTreeNode, expanded, onSetSelectedNode, onClick, ...rest } =
      this.props;

    if (expanded && !expanded.includes('-1')) {
      expanded.push('-1');
    }
    return (
      <>
        <SortableTreeView
          {...rest}
          expanded={expanded}
          onChangeOrder={() => { }} // eslint-disable-line @typescript-eslint/no-empty-function
          aria-label="location picker"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ height: '100%', flexGrow: 1 }}
          selected={'' + selectedTreeNode?.id}
          renderItemContents={this.renderItemContents}
        />
        {/* in order to get the observer to trigger render on name change: */}
        <div className="hidden">{selectedTreeNode?.value}</div>
      </>
    );
  }
}
