import React, { ReactNode, useState } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ReferenceStructure, SimilarStructure, SimilaritySettings } from './SimilarityResultsTypes';
import { GotoCollectionButton } from './SimilarityResultsControls';
import { StructureCardView } from '../renderer';
import { Overlay } from '../layout';
import { HorizontalPropertyListView } from '../Properties/PropertyListView';
import StarBorderIcon from '@mui/icons-material/Star';
import { CollectionInformationView } from './Collections';

const similarityResultViewHeader = (
  result: SimilarStructure,
  banExternalLinks: boolean,
  settings: SimilaritySettings,
): ReactNode => {
  const identifiers = result.identifiers.filter((identifier) => settings.filters.includes(identifier.collection));
  return identifiers.map<React.ReactNode>((identifier) => (
    <GotoCollectionButton key={identifier.name} identifier={identifier} banExternalLinks={banExternalLinks} />
  ));
};

export const SimilarityResultView = (props: {
  hit: SimilarStructure;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
  reference: ReferenceStructure;
  settings: SimilaritySettings;
}) => {
  const { hit, banExternalLinks, canRegisterMolecules, settings } = props;

  const [selected, setSelected] = useState(hit.selected);
  const [registerlink, setRegisterlink] = useState(false);

  const handleMouseclick = (): void => {
    hit.selected = !hit.selected;
    setSelected(hit.selected);
  };
  const handleMouseEnter = () => setRegisterlink(true);
  const handleMouseLeave = () => setRegisterlink(false);

  const smilesSize = 260;
  const header = similarityResultViewHeader(hit, banExternalLinks, settings);

  const tooltipSimilarity = (
    <Typography>
      Similarity scores are derived from the distance between the query and the
      matched structure in the feature space of the CDD neural network. We aimed
      to map the distance to a similarity in a way that matches the experience
      from standard fingerprint-based similarity searches.
    </Typography>
  );
  const footer = (
    <Box sx={{ bottom: 0, left: 0, fontSize: '0.75em' }} >
      <Tooltip title={tooltipSimilarity} arrow placement='bottom'>
        <span>{hit.similarity.toFixed(3)}</span>
      </Tooltip>
      {settings.showCollectionInformation && <CollectionInformationView filters={props.settings.filters} identifiers={hit.identifiers} />}
      <HorizontalPropertyListView
        properties={hit.properties}
        reference={props.reference.properties}
        displayProperties={props.settings.properties}
        separator=','
      />
    </Box>
  );

  return (
    <Box
      onClick={handleMouseclick}
      sx={{ position: 'relative', margin: '0.01em' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StructureCardView
        structure={hit.structure}
        structureSize={smilesSize}
        header={header}
        headerSx={{ minHeight: '2em', paddingRight: '2em', alignItems: 'center' }}
        footer={footer}
        dataTestid={'similarity-structure'}
        selected={selected}
        contentSx={{ zIndex: 1, position: 'relative' }}
        showRegisterLink={canRegisterMolecules && registerlink}
      />
      {hit.selected
        ? <Overlay><StarBorderIcon style={{ float: 'right' }} color='info' /></Overlay>
        : null}
    </Box>
  );
};
