import { observer } from 'mobx-react';
import * as React from 'react';
import {
  ColumnDef,
  SimpleDataTable,
  SimpleFilter,
  AddButton,
} from '@/shared/components';
import { Box, Grid, Typography } from '@mui/material';
import { pluralize } from '@/shared/utils/stringUtils';
import { getRootStore } from '@/stores/rootStore';
import { EditTeamDialog } from './components/EditTeamDialog';
import { TeamSchema, TeamWithProjectIdsSchema } from '@/Accounts/types';

type ColumnId = keyof TeamSchema;

const columns: Array<ColumnDef & { id: keyof TeamSchema }> = [
  {
    label: 'Name',
    id: 'name',
    type: 'link',
  },
  {
    label: '# Members',
    id: 'users_count',
  },
  {
    label: '# Projects',
    id: 'projects_count',
  },
  {
    label: '# Vaults',
    id: 'vaults_count',
  },
  {
    id: 'created_at',
    label: 'Created date',
    type: 'date',
  },
  {
    id: 'updated_at',
    label: 'Last updated',
    type: 'date',
  },
];

@observer
export class TeamsPage extends React.Component {
  renderCell(columnId: ColumnId, team: TeamWithProjectIdsSchema) {
    switch (columnId) {
      case 'vaults_count':
      case 'projects_count':
      case 'users_count':
        return '' + this.getFieldValue(columnId, team);
    }
  }

  // for sorting
  getFieldValue(columnId: ColumnId, team: TeamWithProjectIdsSchema) {
    const {
      accountsStore: {
        teamsStore,
      },
    } = getRootStore();

    switch (columnId) {
      case 'projects_count':
        return team.project_ids.length;

      case 'vaults_count':
        return teamsStore.getVaultCountForTeam(team);

      case 'users_count':
        return teamsStore.getUserCountForTeam(team);
    }
  }

  handleClickNew = () => {
    const {
      accountsStore: {
        teamsStore: {
          editTeamStore: { handleNewTeam },
        },
      },
    } = getRootStore();

    handleNewTeam();
  };

  handleClickRow(team: TeamSchema) {
    if (team) {
      const {
        accountsStore: {
          teamsStore: {
            editTeamStore: { handleEditTeam },
          },
        },
      } = getRootStore();

      handleEditTeam(team);
    }
  }

  renderTopRow() {
    const {
      accountsStore: {
        currentAccount,
        teamsStore: { teams, filterText, setFilterText },
      },
    } = getRootStore();
    return (
      <div className='top-row'>
        <Grid container alignItems='center'>
          <Grid item xs={5}>
            <Box display='flex' justifyContent='left' alignItems='center'>
              <SimpleFilter
                value={filterText}
                onChangeValue={setFilterText}
              />
              <div className="counts">
                {currentAccount && (
                  <Typography>{pluralize(teams.length, 'team')}</Typography>
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box display='grid' justifyContent='end'>
              <AddButton
                className='add-associated-model'
                onClick={this.handleClickNew}
                text={'Create a new team'}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const {
      accountsStore: {
        teamsStore: {
          editTeamStore,
          displayRows,
          sortBy,
          setSortBy,
        },
      },
    } = getRootStore();

    return (
      <div className='TeamsPage'>
        {this.renderTopRow()}
        <SimpleDataTable
          stickyHeaders
          columns={columns}
          rows={displayRows}
          renderCell={this.renderCell}
          getFieldValue={this.getFieldValue}
          onClickRow={this.handleClickRow}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />

        <EditTeamDialog
          store={editTeamStore}
        />
      </div>
    );
  }
}
