import { PropsOf } from '@emotion/react';
import { Tooltip } from '@mui/material';
import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import camelCaseKeys from '@/shared/utils/camelCaseKeys.js';

interface Props extends Partial<PropsOf<typeof Tooltip>> {
  title: React.ReactNode;
  exclamationIcon?: 'float';
  offsetX?: number;
  offsetY?: number;
  float?: string;
}

export const ChildWithTooltip = (props: Props) => {
  props = camelCaseKeys(props);

  let reactProps = {};
  const exclamationIcon = props.exclamationIcon;
  const placement = props.placement ?? 'bottom';
  const offsetX = props.offsetX ?? 0;
  const offsetY = props.offsetY ?? (placement === 'bottom' ? 20 : 0);

  if (props.offsetX !== undefined || props.offsetY !== undefined) {
    reactProps = {
      slotProps: {
        'popper':
        {
          'modifiers':
            [{
              'name': 'offset',
              'options': {
                'offset': [offsetX, offsetY],
              },
            }],
        },
      },
    };
  }

  if (exclamationIcon === 'float') {
    return <Tooltip placement={placement} {...reactProps} title={props.title}>
      <span style={{ position: 'relative' }} >{props.children}
        <WarningAmberIcon style={{ color: '#ccc', position: 'absolute', width: 20, right: 5, top: 10 }} />
      </span>
    </Tooltip>;
  }

  const style: React.CSSProperties = {};

  if (props.float) {
    style.float = props.float as typeof style.float;
  }

  return <Tooltip style={style} placement={placement} {...reactProps} title={props.title}>
    <span>{props.children}</span>
  </Tooltip>;
};
