import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import structurelessImage from 'ASSETS/images/structureless/molecule.svg';
import { Img } from '@/shared/components/sanitizedTags.js';
import { ChemistryImage } from '@/shared/components';

/*
 * NOTE:
 * We rely on elasticsearch to properly escape HTML entities in the highlights.
 */

type Props = {
  suggestion: {
    mrv: string;
    molecule_details?: {
      molfile: string;
    };
    display: string;
    vault: string;
    type: string;
  };

  itemProps?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  selected?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default class Suggestion extends React.Component<Props> {
  get moleculeRenderSrc() {
    const { suggestion } = this.props;
    return (suggestion.molecule_details?.molfile ?? suggestion.mrv);
  }

  renderMolecule() {
    const { moleculeRenderSrc } = this;
    return moleculeRenderSrc ? <ChemistryImage src={moleculeRenderSrc} /> : <Img src={structurelessImage} />;
  }

  render() {
    const { suggestion, itemProps, selected } = this.props;
    const { display, vault } = suggestion;

    let className = 'link-suggestion';
    if (selected) {
      className += ' link-suggestion--selected';
    }

    return (
      <MenuItem
        {...itemProps}
        className={className}
        selected={selected}
        component="div"
      >
        {this.renderMolecule()}
        <div className="link-suggestion__content">
          <span
            className="link-suggestion__label"
            dangerouslySetInnerHTML={{ __html: display }}
          />
          <div className="link-suggestion__caption">
            <Typography variant="caption">
              <span className="link-suggestion__caption-text">
                {vault}
              </span>
            </Typography>
          </div>
        </div>
      </MenuItem>
    );
  }
}
