import React from 'react';
import { StructureEditor, StructureFormat } from './StructureEditorTypes';
import { addKetcherStructure, getKetcherStructure, prepareStructureForQuery, setKetcherSettings, setKetcherStructure } from './ketcherUtils';
import { StructureEditor as EditorComponent } from '@cdd/ui-kit/lib/components/composite/structureEditor/v1';
import { theme } from '@cdd/ui-kit/lib/theme';
import { ThemeProvider } from '@cdd/ui-kit/lib/theme/provider';
import { Ketcher } from 'ketcher-core';

type Props = {
  toolbars?: string;
  structure?: string;
  moleculesOnly?: boolean;
  isSearch?: boolean;
};

const HIDDEN_BUTTONS = ['fullscreen', 'recognize', 'mview'];
const REACTION_BUTTONS = ['arrows', 'reaction-plus', 'reaction-mapping-tools', 'rgroup-attpoints', 'rgroup-fragment', 'shape-ellipse', 'shape-line', 'shape-rectangle', 'text'];
const KETCHER_SETTINGS = {
  'general.dearomatize-on-load': true,
  ignoreChiralFlag: true,
  disableQueryElements: ['H+', 'Pol', 'GH', 'GH*', 'ACH', 'ABH', 'AYH', 'ALH', 'AEH', 'AHH', 'AOH',
    'CYH', 'CXH', 'CBH', 'ARH', 'CAH', 'CEH', 'CHH', 'HAH', 'AH', 'QH', 'MH', 'XH'],
  disableCustomQuery: true,
};

export class KetcherEditor extends React.Component<Props> implements StructureEditor {
  private ketcherEditor: Ketcher;

  componentWillUnmount() {
    // ketcher uses same instance on reopens, so we need to clear data
    window.ketcher.setMolecule('');
  }

  handleOnLoad = async (ketcher: Ketcher) => {
    window.ketcher = ketcher;
    this.ketcherEditor = ketcher;
    await setKetcherSettings(KETCHER_SETTINGS, ketcher);

    const { structure } = this.props;
    await setKetcherStructure(structure, ketcher);
  };

  addStructure = async (structure: string) => {
    return await addKetcherStructure(structure, this.ketcherEditor);
  };

  getStructure = async (format = StructureFormat.MRV) => {
    if (this.props.isSearch && (format == StructureFormat.MOL || format == StructureFormat.MOLV3000)) {
      const mol = await getKetcherStructure(StructureFormat.MOLV3000, this.ketcherEditor);
      try {
        return prepareStructureForQuery(mol);
      } catch (ex) {
        console.warn(ex);
        return mol;
      }
    }
    return await getKetcherStructure(format, this.ketcherEditor);
  };

  render() {
    const hiddenButtons = [...HIDDEN_BUTTONS];
    if (this.props.moleculesOnly) hiddenButtons.push(...REACTION_BUTTONS);

    return <ThemeProvider theme={theme}>
      <EditorComponent
        onInit={ketcher => this.handleOnLoad(ketcher as any)} // eslint-disable-line @typescript-eslint/no-explicit-any
        buttons={hiddenButtons.reduce((acc, cur) => ({ ...acc, [cur]: { hidden: true } }), {})}
        errorHandler={console.log}
        staticResourcesUrl="/public"
      />
    </ThemeProvider>;
  }
}
