import { useBoolean } from '@cdd/ui-kit/lib/hooks/useBoolean';
import { ShouldRender } from '@cdd/ui-kit/lib/components/conditionals/ShouldRender/v1';
import React, { type FC } from 'react';
import { FilePreviewInner, UploadedFile } from './FilePreviewInner';
import { Img } from '@/shared/components/sanitizedTags.js';

type IProps = {
  file: UploadedFile;
  extension: string;
  format: string;
  contentAsBase64: string;
  downloadSrc: string;
  thumbnailsAllowed: boolean;
  largeThumbnailHref: string;
  withDialog?: boolean;
};

export const FilePreview: FC<IProps> = ({
  file,
  extension,
  format,
  contentAsBase64,
  downloadSrc,
  thumbnailsAllowed,
  largeThumbnailHref,
  withDialog = true,
}) => {
  const {
    setTrue: setPreviewIsOpen,
    setFalse: setPreviewIsClosed,
    value: isPreviewOpen,
  } = useBoolean(!withDialog);

  if (!file) {
    return null;
  }

  return (
    <>
      <ShouldRender shouldRender={withDialog}>
        <Img
          alt={file.name}
          title={file.name}
          id={`file_${file.id}_thumbnail`}
          src={largeThumbnailHref}
          onClick={setPreviewIsOpen}
          style={{ cursor: 'pointer' }}
        />
      </ShouldRender>
      <ShouldRender shouldRender={isPreviewOpen}>
        <FilePreviewInner
          open={isPreviewOpen}
          onClose={setPreviewIsClosed}
          file={file}
          href={downloadSrc}
          thumbnailsAllowed={thumbnailsAllowed}
          contentAsBase64={contentAsBase64}
          format={format}
          extension={extension}
          largeThumbnailHref={largeThumbnailHref}
          withDialog={withDialog}
        />
      </ShouldRender>
    </>
  );
};
